import { hash, delay } from "../../common/Utils";
import JanusGroupHandler from "./Handlers/JanusGroupHandler";
const Janus = window.Janus;
const JANUS_SERVER_URL = [
  "wss://janus.plugapp.net/ws/janus",
  "wss://janus.plugapp.net/janusbase/janus",
];

export default class JanusClient {
  constructor(user, groups) {
    this.janus = null;
    this.clients = {};
    this.email = null;
    this.createClientMutex = {};
  }

  janusInit() {
    return new Promise((res, reject) => {
      Janus.init({
        debug: true,
        callback: () => {
          this.janus = new Janus({
            server: JANUS_SERVER_URL,
            iceServers: [
              {
                urls: "stun:stun.plugapp.net:3478",
              },
              {
                urls: "turn:turn.plugapp.net:3478",
                username: "plugapp",
                credential: "plugapp2022xxx",
              },
              { urls: "stun:stun.dial.social:7786" },
              {
                urls: "turn:turn.dial.social:7786",
                username: "dial",
                credential: "dialuser2022xxx",
              },
              { urls: "stun:stun.l.google.com:19302" },
            ],
            success: () => {
              console.log("janus server sucessfully connected");
              res();
            },
            error: (error) => {
              reject(error);
            },
            destroyed: () => {
              console.log("connections has been destroyed");
              this.clients = {};
              this.createClientMutex = {};
              this.init({ email: this.email }).then(() => {});
            },
          });
        },
        error: (error) => {
          reject(error);
        },
        destroyed: () => {
          console.log("connections has been destroyed");
          this.clients = {};
          this.createClientMutex = {};
          this.init({ email: this.email }).then(() => {});
        },
      });
    });
  }

  async init(user, force = false) {
    if (!force && this.janus && this.janus.isConnected() && this.email) {
      return "already connected";
    }

    this.email = user.email;
    if (force) {
      await this.join(null, false, true);
      return;
    }
    this.clients = {};
    this.createClientMutex = {};
    await this.janusInit();
    return "connected";
  }

  async reset() {
    await Promise.all(
      Object.keys(this.clients).map(async (channelId) => {
        try {
          await this.leave(channelId);
          // console.log(this.clients);
        } catch (e) {
          console.log(e);
        }
      })
    );
  }

  async lineoff(client) {
    try {
      await client.lineoff();
    } catch (e) {
      console.log(`lineoff, ${e}`);
    }
  }

  async lineon(client) {
    console.log("lineon", hash(client.channelName));
    try {
      await client.lineon();
    } catch (e) {
      console.log(`lineon, ${e}`);
    }
    await this.adjustvolumes();
  }

  async forcelineoff(groupid) {
    const channelId = `plug.${groupid}`;
    console.log(channelId);
    console.log(`Force line off ${channelId}`);
    if (this.clients[channelId]) {
      await this.lineoff(this.clients[channelId]);
    }
    Object.keys(this.clients).map(async (cid) => {
      if (cid !== channelId) {
        await this.highvolume(cid);
      }
    });
  }

  async joinOrCreateRoom(uid, channelId, mute) {
    console.log("joinOrCreateRoom", channelId);
    while (this.createClientMutex[channelId]) {
      console.log("waiting...joinOrCreateRoom", channelId);
      await delay(500);
    }

    if (this.clients[channelId]) {
      try {
        await this.clients[channelId].joinRoom();
      } catch (e) {
        console.log("joinOrCreateRoom ERROR : ", e);
        await this.init({ email: this.email }, true);
        return this.joinOrCreateRoom(uid, channelId, mute);
      }
      return this.clients[channelId];
    }
    this.createClientMutex[channelId] = true;
    let client = new JanusGroupHandler(
      this.janus,
      channelId,
      uid,
      mute && channelId !== `plug.${mute}` ? 0 : 100
    );

    await client.checkconnected();
    await client.joinRoom();
    this.createClientMutex[channelId] = false;
    return client;
  }

  async join(groupid, lineon, mute) {
    let channelId = `plug.${groupid}`;
    console.log(this.janus);
    console.log(this.janus.isConnected());
    if (!this.janus.isConnected()) {
      console.log(window.showNotification);
      let pastConnection = [];
      Object.keys(this.clients).forEach((Id) => {
        pastConnection.push(Id);
      });
      await this.init({ email: this.email });
    }
    if (!groupid) {
      return;
    }
    console.log(`Joining voice channel ${channelId} and `, mute);
    const client = await this.joinOrCreateRoom(this.email, channelId, mute);
    console.log(client);
    this.clients[channelId] = client;

    if (lineon) {
      this.lineon(client);
    }
    console.log(channelId !== `plug.${mute}`, channelId, `plug.${mute}`);
    if (mute && channelId !== `plug.${mute}`) {
      this.lowvolume(channelId);
    }

    return;
  }

  async leave(channelId) {
    if (this.clients[channelId]) {
      await this.lineoff(this.clients[channelId]);
      await this.clients[channelId].leaveRoom();
      // console.log(this.clients);
      // delete this.clients[channelId];
    }
  }

  async lowvolume(channelId) {
    const client = this.clients[channelId];
    if (!client) return;
    console.log("setting volume low", channelId);
    const LOW_VOLUME_LEVEL = 1;
    await client.setVolume(LOW_VOLUME_LEVEL);
  }

  async highvolume(channelId) {
    const client = this.clients[channelId];
    if (!client) return;
    console.log("setting volume high", channelId);
    const HIGH_VOLUME_LEVEL = 100;
    await client.setVolume(HIGH_VOLUME_LEVEL);
  }

  async adjustvolumes() {
    let lineoncid = (
      Object.values(this.clients).filter((c) => {
        return !c.muted;
      })[0] || {}
    ).channelName;
    console.log(lineoncid);
    await Promise.all(
      Object.keys(this.clients).map(async (cid) => {
        if (cid == lineoncid || !lineoncid) await this.highvolume(cid);
        else await this.lowvolume(cid);
      })
    );
  }
  async destroy() {
    console.log(this.janus);
    if (this.janus) {
      await this.janus.destroy(() => {
        console.log("connection destroyed");
      });
      this.janus = null;
      this.email = null;
    }
  }
}
