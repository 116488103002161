import React, { useEffect, useRef, useState } from "react";
import "../CreateGroup/CreateGroup.css";
import { UploadOutlined, AudioOutlined } from "@ant-design/icons";
import "./ChatBox.css";
import { connect } from "react-redux";
import {
  setNewMsg,
  setNewMsgInGroup,
  showChatBoxAction,
} from "../../../Redux/Actions/Action";
import { activateGroup } from "../../../Redux/Actions/Action";
import { CrossLogo, ChatSend, chatLogo } from "../../../assets/index.js";
import { sendMessageAction } from "../../../Redux/Actions/WsActions";
import ParsedMsg from "../ParsedMsg/ParsedMsg";
// import MimeTypes from "mime-type";
import {
  trackEventActions,
  uploadFileToCloud,
  getDownloadableUrl,
} from "../../../Redux/Actions/firebaseActions";
import { getGroupMessageHistory } from "../../../Redux/Actions/firebaseActions";
import { FileIcon, defaultStyles } from "react-file-icon";

import { hash } from "../../../logic/common/Utils";
import { message, Spin } from "antd";
import RecordView from "../RecordView/RecordView";
import ChatAudio from "../ChatAudio/ChatAudio";
// random dummy list of user who use this image;
const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",

  "#ffbf9f",
  "#f1a200",
];

function ScrollBottom(props) {
  useEffect(() => {
    let ref = props.refrence;
    if (ref.current.scrollHeight > ref.current.clientHeight) {
      setTimeout(() => {
        ref.current.scrollTop =
          ref.current.scrollHeight + ref.current.clientHeight;
      }, 250);
    }
  }, []);
}

function ChatBox(props) {
  const [textMessage, setMessage] = useState("");
  const [fileType, setFileTye] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showRecordPopUp, setViewRecordPopUp] = useState(false);

  const ref = useRef();

  const handleSendMessage = async () => {
    if (textMessage === "" && !fileName && !fileType) return;

    let payload = {};
    payload.groupid = props.activeGroup.groupid;
    payload.displayName = props?.user?.displayName;
    try {
      if (file && fileName && fileType) {
        if (file.size > 52428800) {
          message.info("file size more than 50mb");
          setFile(null);
          setFileName(null);
          setFileTye(null);
          setMessage("");
          return;
        }
        payload.type = "ATTACHMENT";
        // save the file in cloude storage and get the url.
        setLoading(true);

        const Url = await uploadFileToCloud(file, fileType);
        setLoading(false);
        payload.filename = fileName;
        payload.url = Url;
        payload.fileext = fileType;
        payload.size = file.size;
        if (textMessage) payload.message = textMessage;
        console.log(payload);
      } else {
        payload.type = "TEXT";

        payload.message = textMessage;
      }

      await props.sendMessageAction(payload);
    } catch (e) {
      console.log(e);
    }
    setFile(null);
    setFileName(null);
    setFileTye(null);
    setMessage("");
  };
  const handleFileChange = (event) => {
    if (!event.target.files[0]) return;
    const fileName = event.target.files[0].name;

    const iconType = fileName.substr(
      fileName.lastIndexOf(".") + 1,
      fileName.length
    );
    setFile(event.target.files[0]);
    setFileName(fileName.substr(0, fileName.lastIndexOf(".")));
    setFileTye(iconType);
  };

  const handleDownloadFile = async (path, filename, fileext) => {
    const url = await getDownloadableUrl(path);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename + "." + fileext);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSendMessage();
    }
  };

  function getTime(timestamp) {
    let time = new Date(timestamp);

    let dateParts = time.toDateString().split(" ");
    return (
      dateParts[2] + " " + dateParts[1] + " , " + time.toLocaleTimeString()
    );
  }
  return (
    <div className="modal_box" style={{ display: "flex" }}>
      <div className="modal_container">
        <CrossLogo
          className="modal_crg_cross_logo"
          onClick={() => {
            trackEventActions("chatoff", {
              group: props.activeGroup.groupName,
              groupid: props.activeGroup.groupid,
            });

            props.closeChatBoxAction(props?.activeGroup?.groupid);
          }}
        />
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "70px",
              boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.06)",
            }}
          >
            <h2 className="chat_box_heading">
              Chat:
              <span
                style={{
                  color: "#f1a200",
                  fontWeight: "bolder",
                  fontFamily: "Inter",
                  marginLeft: "5px",
                }}
              >
                {props.activeGroup.groupName}
              </span>
            </h2>
          </div>
          <div className="modal_crg_add_box" style={{ marginTop: "10px" }}>
            <div
              className="modal_crg_list"
              style={{ height: "95%", transition: " transform 3s 1s" }}
            >
              <div
                style={{
                  overflowY: "auto",
                  width: "90%",
                  maxHeight: "300px",
                  height: "85%",
                  paddingRight: "10px",
                  display: "flex",
                  flexDirection: props.groupsChats?.[
                    props?.activeGroup?.groupid
                  ]?.length
                    ? "column-reverse"
                    : "column",
                }}
                className="chat_box_wrapper"
                ref={ref}
              >
                {props.groupsChats?.[props?.activeGroup?.groupid]?.length ? (
                  <React.Fragment>
                    <div style={{ flex: "1", flexShrink: "1" }}></div>

                    {props.groupsChats[props.activeGroup.groupid].map(
                      (msgObj, idx) => {
                        return (
                          <div
                            key={`${msgObj.groupid}-${msgObj.createdat}`}
                            style={{
                              fontFamily: "Inter",
                              marginBottom: "20px",
                              alignSelf:
                                msgObj.senderuid === props.user.uid
                                  ? "flex-end"
                                  : "flex-start",
                              width: "50%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems:
                                msgObj.senderuid === props.user.uid
                                  ? "flex-end"
                                  : "flex-start",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                color:
                                  colorList[
                                    hash(
                                      msgObj?.senderuid?.split("@")?.[0] ??
                                        msgObj?.senderemail?.split("@")?.[0]
                                    ) % 11
                                  ],
                                marginBottom: "0px",
                                fontWeight: "bolder",
                                fontFamily: "Inter",
                              }}
                            >
                              {msgObj.senderuid === props.user.uid
                                ? "you"
                                : msgObj.sendername
                                ? msgObj.sendername
                                : msgObj.senderemail?.split("@")?.[0]}
                            </p>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#121212",
                                marginBottom: "0px",
                                fontFamily: "Inter",
                                fontWeight: 500,
                                display: "flex",
                                flexDirection: "column",
                                alignItems:
                                  msgObj.senderuid === props.user.uid
                                    ? "flex-end"
                                    : "flex-start",
                              }}
                              className="fileIconMsg"
                            >
                              {msgObj.url ? (
                                <div
                                  className="uploadedFile"
                                  onClick={() => {
                                    handleDownloadFile(
                                      msgObj.url,
                                      msgObj.filename,
                                      msgObj.fileext
                                    );
                                  }}
                                >
                                  <FileIcon
                                    extension={msgObj.fileext}
                                    {...defaultStyles[msgObj.fileext]}
                                    color="#f1a2008f"
                                  />
                                </div>
                              ) : null}
                              {msgObj.url ? (
                                <React.Fragment>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#444",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    {msgObj.filename + "." + msgObj.fileext}
                                  </p>
                                  <div
                                    style={{ fontSize: "10px", color: "#aaa" }}
                                  >
                                    Click to Download !
                                  </div>
                                </React.Fragment>
                              ) : null}
                              {msgObj.mediaurl ? (
                                <ChatAudio url={msgObj.mediaurl} />
                              ) : null}
                              {/* {msgObj.filename ? (
                                <p>{msgObj.filename}</p>
                              ) : null} */}
                              {msgObj.text ? (
                                <ParsedMsg text={msgObj.text} />
                              ) : null}
                            </div>
                            <p
                              style={{
                                fontSize: "11px",
                                color: "#121212",
                                fontFamily: "Inter",
                                marginBottom: "0px",
                                opacity: "0.5",
                              }}
                            >
                              {getTime(msgObj.createdat)}
                            </p>
                          </div>
                        );
                      }
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {props.hideLoadingChat?.[
                        props?.activeGroup?.groupid
                      ] ? null : (
                        <button
                          style={{}}
                          className="load_more_btn"
                          onClick={() => {
                            props
                              .findMoreMessage(props?.activeGroup?.groupid)
                              .then(() => {})
                              .catch((e) => {
                                console.log(e);
                              });
                          }}
                        >
                          load more
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <img src={chatLogo} alt="" className="chat_logo" />
                    <span style={{ opacity: 0.6 }}>Say Hello!!</span>
                  </div>
                )}
                {props.newMessage ? <ScrollBottom refrence={ref} /> : null}
              </div>
              <input
                className="chat_input"
                placeholder="Write something in Group"
                value={textMessage}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                onKeyDown={handleKeyPress}
                disabled={showRecordPopUp ? true : false}
              />
              <ChatSend
                className="chat_send_logo"
                onClick={() => {
                  handleSendMessage();
                }}
                style={{
                  color: textMessage || fileName ? "#f1a200" : "#121212",
                  opacity: textMessage || fileName ? 1 : 0.3,
                }}
              />
              {fileType ? (
                <div className="fileIcon">
                  {loading ? <Spin /> : null}
                  <CrossLogo
                    style={{
                      alignSelf: "flex-end",
                      display: loading ? "none" : "block",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setFile(null);
                      setFileName(null);
                      setFileTye(null);
                      setMessage("");
                    }}
                  />
                  <FileIcon
                    extension={fileType}
                    {...defaultStyles[fileType]}
                    style={{ height: "50px", width: "30px" }}
                    color="#f1a2008f"
                  />
                  <p
                    style={{
                      color: "#f1a200",
                      width: "70%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {fileName}
                  </p>
                </div>
              ) : null}
              <label className="upload_label">
                <UploadOutlined className="upload_btn" />
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />
              </label>
              <label
                className="audio_label"
                onClick={() => {
                  setViewRecordPopUp(true);
                }}
              >
                <AudioOutlined className="voice_btn" />
              </label>
              {showRecordPopUp ? (
                <RecordView
                  closeRecordPopUp={() => {
                    setViewRecordPopUp(false);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  closeChatBoxAction: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(showChatBoxAction(false));
    dispatch(activateGroup({ groupName: "", members: [], groupid: "" }));
  },
  sendMessageAction: (payload) => {
    return dispatch(sendMessageAction(payload));
  },
  setMessage: (groupid) => {
    dispatch(setNewMsg({ [groupid]: false }));
  },
  findMoreMessage: (groupid) => {
    return dispatch(getGroupMessageHistory(groupid, true));
  },
  //   makeRequest: (type, payload) => dispatch(makeRequest(type, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);
