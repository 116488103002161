import { delay } from "../../../../common/Utils";
export class PlugWsSignalsHandler {
  constructor(socket, store, actions, janusActions) {
    this.socket = socket;
    this.store = store;
    this.actions = actions;
    this.janusActions = janusActions;
    this.signalSubscribed = false;
  }
  async subscribe() {
    if (!this.signalSubscribed) {
      this.socket.on("signal_speaking", async ({ userid, groupid }) => {
        console.log("signal_speaking", userid, groupid);
      });
      this.socket.on("signal_busy", async (response) => {
        console.log("signal_busy", response);
        let { uid, activity } = response;
        if (activity === "busyon") {
          this.store.dispatch(this.actions.setBusyStatus({ [uid]: true }));
        } else {
          this.store.dispatch(this.actions.setBusyStatus({ [uid]: false }));
        }
      });
      this.socket.on("signal_lineon", async (response) => {
        console.log("signal_line", response.activity, response);
        let { uid, groupid, activity, timestamp } = response;
        let lineOnStatus = this.store.getState().lineOnStatus;
        let latestLineOnTime = this.store.getState().latestLineOnTime;
        let groups = this.store.getState().groups;
        let userLineOnGroup = this.store.getState().showLineOnPopUp;
        let myUid = this.store.getState().user.uid;
        // let myUid = this.store.getState().user.uid;
        // let myLineOnGroup = null;
        // let lineOnTimer = null;

        if (activity === "lineon") {
          this.store.dispatch(this.actions.lineOnGroups(groupid));
          // if (uid === myUid) {
          //   myLineOnGroup = groupid;
          //   if (lineOnTimer) clearTimeout(lineOnTimer);

          //   lineOnTimer = setTimeout(() => {
          //     console.log("lineOff since no one have line on");
          //   }, 5000);
          // } else {
          //   console.log(myLineOnGroup, groupid);
          //   if (myLineOnGroup === groupid) {
          //     console.log(lineOnTimer);
          //     if (lineOnTimer) clearTimeout(lineOnTimer);
          //     console.log(lineOnTimer);
          //   }
          // }

          latestLineOnTime = {
            ...latestLineOnTime,
            [groupid]: {
              ...latestLineOnTime[groupid],
              lineOnTime: timestamp,
            },
          };
          let localLastestLineOn = {};
          Object.keys(latestLineOnTime).forEach((groupid) => {
            localLastestLineOn[groupid] = latestLineOnTime[groupid].lineOnTime;
          });

          localStorage.setItem(
            "plugApp_lineon_time",
            JSON.stringify(localLastestLineOn)
          );

          groups.sort((a, b) => {
            if (userLineOnGroup && userLineOnGroup === b.groupid) return 1;
            else if (userLineOnGroup && userLineOnGroup === a.groupid)
              return -1;
            else
              return (
                latestLineOnTime[b.groupid].lineOnTime -
                latestLineOnTime[a.groupid].lineOnTime
              );
          });
          groups.forEach((group_obj) => {
            if (group_obj.groupid === groupid) {
              group_obj.members.sort((a, b) => {
                if (a.uid === myUid) return -1;
                if (b.uid === myUid) return 1;
                if (a.uid === uid) return -1;
                if (b.uid === uid) return 1;
              });
            }
          });
          this.store.dispatch(this.actions.setNotification({ [groupid]: uid }));
          // after 1 sec of setting make it null;
          setTimeout(() => {
            this.store.dispatch(this.actions.setNotification({}));
          }, 1000);
          this.store.dispatch(
            this.actions.setLatestLineonTime(latestLineOnTime)
          );

          this.store.dispatch(this.actions.addGroupAction(groups));

          lineOnStatus = {
            ...lineOnStatus,
            [groupid]: { ...lineOnStatus[groupid], [uid]: true },
          };
          this.store.dispatch(this.actions.lineOnStatus(lineOnStatus));
        } else {
          this.store.dispatch(this.actions.lineOffGroups(groupid));
          delete lineOnStatus[groupid][uid];
          this.store.dispatch(this.actions.lineOnStatus(lineOnStatus));
          groups.forEach((group_obj) => {
            if (group_obj.groupid === groupid) {
              group_obj.members.sort((a, b) => {
                if (a.uid === myUid) return -1;
                if (b.uid === myUid) return 1;
                if (a.uid === uid) return 1;
                if (b.uid === uid) return -1;
              });
            }
          });
          this.store.dispatch(this.actions.addGroupAction(groups));
          // if (uid === myUid) {
          //   console.log(lineOnTimer);
          //   if (lineOnTimer) clearTimeout(lineOnTimer);
          //   myLineOnGroup = null;
          //   console.log(lineOnTimer);
          // } else {
          //   if (myLineOnGroup === groupid) {
          //     if (lineOnTimer) clearTimeout(lineOnTimer);
          //     if (Object.keys(lineOnStatus[groupid]).length == 1) {
          //       console.log(lineOnStatus[groupid]);
          //       lineOnTimer = setTimeout(() => {
          //         console.log("lineOff since no one have line on");
          //       }, 1000);
          //     }
          //   }
          // }
        }
      });
    }

    this.socket.emit(
      "subscribe",
      {
        handlerType: "signals",
      },
      async (error, response) => {
        console.log(error, response);
        // console.log(this.store, this.actions, response.signal_lineon);
        let latestLineOnTime = this.store.getState().latestLineOnTime;
        let groups = this.store.getState().groups;
        let myUid = this.store.getState().user.uid;
        let lineOnInGroup = this.store.getState().showLineOnPopUp;
        this.signalSubscribed = true;
        let lineOnStatus = {};
        let signalBusy = {};

        Object.keys(response.signal_lineon).forEach((groupid) => {
          lineOnStatus[groupid] = {};
          response.signal_lineon[groupid].forEach((uid) => {
            lineOnStatus[groupid][uid] = true;
          });
        });

        Object.keys(lineOnStatus).forEach(async (groupid) => {
          if (response.signal_lineon[groupid].length) {
            console.log(
              "create the janus connection for user in group",
              groupid
            );
            latestLineOnTime = {
              ...latestLineOnTime,
              [groupid]: {
                ...latestLineOnTime[groupid],
                lineOnTime: new Date().getTime(),
              },
            };

            await this.store.dispatch(
              this.janusActions.createJanusHandle(
                groupid,
                groupid === lineOnInGroup ? true : false,
                null
              )
            );
            await delay(1000);
          }
        });

        response.signal_busy.forEach((uid) => {
          signalBusy[uid] = true;
        });

        groups.sort((a, b) => {
          return (
            latestLineOnTime[b.groupid].lineOnTime -
            latestLineOnTime[a.groupid].lineOnTime
          );
        });
        groups.forEach((group_obj) => {
          group_obj.members.sort((a, b) => {
            if (a.uid === myUid) return -1;
            if (b.uid === myUid) return 1;
            if (lineOnStatus?.[group_obj.groupid]?.[a.uid]) return -1;
            if (lineOnStatus?.[group_obj.groupid]?.[b.uid]) return 1;
            return 0;
          });
        });

        this.store.dispatch(this.actions.addGroupAction(groups));
        this.store.dispatch(this.actions.setLatestLineonTime(latestLineOnTime));
        this.store.dispatch(this.actions.setBusyStatus(signalBusy));
        let localLastestLineOn = {};
        Object.keys(latestLineOnTime).forEach((groupid) => {
          localLastestLineOn[groupid] = latestLineOnTime[groupid].lineOnTime;
        });

        localStorage.setItem(
          "plugApp_lineon_time",
          JSON.stringify(localLastestLineOn)
        );
        this.store.dispatch(this.actions.lineOnStatus(lineOnStatus));
        console.log("signals has been connnected");
      }
    );
  }

  makeRequest(requestType, payload) {
    return new Promise((res, rej) => {
      this.socket.emit(
        "makeRequest",
        {
          handlerType: "signals",
          data: {
            request: requestType,
            payload: payload,
          },
        },
        (error, response) => {
          console.log(`signal ${requestType}`, error, response);
          if (error) {
            rej();
          } else {
            res();
          }
        }
      );
    });
  }
}
