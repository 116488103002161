import { PlugWsUsersHandler } from "./Handlers/Users/index.js";
import { PlugWsGroupsHandler } from "./Handlers/Groups/index.js";
import { PlugWsSignalsHandler } from "./Handlers/Signals/index.js";
import io from "socket.io-client";
import { isElectron } from "../../common/Utils.js";

export class PlugWsClient {
  constructor() {
    this.socket = {};
    this.groupHandler = null;
    this.userHandler = null;
    this.signalHandler = null;
    this.handlerCreated = false;
    this.store = null;
    this.actions = null;

    this.lastReconnectionTime = 0;
    this.getTokenActions = null;
    this.wsActions = null;
    this.janusActions = null;
  }

  // init call for initialising the socket.
  async init(store, actions, wsActions, janusActions, getTokenActions) {
    this.store = store;
    this.actions = actions;
    this.getTokenActions = getTokenActions;
    this.wsActions = wsActions;
    this.janusActions = janusActions;
    let Token = await this.getTokenActions();
    this.socket = await io.connect(
      // "https://ws-server-futfblglha-em.a.run.app",
      // "localhost:8080",
      "https://ws.plugapp.net",

      {
        auth: { token: Token },
        query: "platform=web",

        reconnection: false,

        transports: ["websocket"],
      }
    );

    this.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
      this.reconnect();
    });

    this.socket.on("connect", async () => {
      console.log("connected", this.socket);

      console.log("new Instance has been created");

      if (!this.handlerCreated) {
        this.groupHandler = new PlugWsGroupsHandler(
          this.socket,
          store,
          actions
        );
        this.userHandler = new PlugWsUsersHandler(this.socket, store, actions);
        this.signalHandler = new PlugWsSignalsHandler(
          this.socket,
          store,
          actions,
          janusActions
        );
        this.handlerCreated = true;
      }
      await this.groupHandler.subscribe();
      // find if the plug is on then send user_online;
      // and if the line is on then send signal_lineon;

      if (isElectron() || this.store.getState().plugON) {
        this.store.dispatch(this.wsActions.setPlugOn(true));
      }

      if (store.getState().showLineOnPopUp) {
        store.dispatch(
          wsActions.setLineOn({ groupid: store.getState().showLineOnPopUp })
        );
      }
    });

    this.socket.on("disconnect", async (err) => {
      console.log("disconnected", err);
      if (new Date().getTime() - this.lastReconnectionTime < 2000) return;
      this.lastReconnectionTime = new Date().getTime();

      await this.reconnect();
    });

    // Listen for reconnect event
    this.socket.io.on("reconnect", () => {
      console.log("reconnecting");
    });
  }

  async reconnect() {
    console.log("reconnecting the socket");
    let token = await this.getTokenActions();
    console.log(token);
    if (this.socket.connected) return;
    this.socket.destroy();
    this.socket = await io.connect(
      // "https://ws-server-futfblglha-em.a.run.app",
      // "localhost:8080",
      "https://ws.plugapp.net",

      {
        auth: { token },
        query: "platform=web",
        reconnection: false,
        transports: ["websocket"],
      }
    );
    this.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
      this.reconnect();
    });
    this.socket.on("connect", async () => {
      console.log("connected", this.socket);

      console.log("new Instance has been created");

      this.groupHandler = new PlugWsGroupsHandler(
        this.socket,
        this.store,
        this.actions
      );
      this.userHandler = new PlugWsUsersHandler(
        this.socket,
        this.store,
        this.actions
      );
      this.signalHandler = new PlugWsSignalsHandler(
        this.socket,
        this.store,
        this.actions,
        this.janusActions
      );

      await this.groupHandler.subscribe();
      // if (this.store.getState().plugON) {
      //   console.log("calling this while plugOn");
      //   this.userHandler.subscribe();
      //   this.signalHandler.subscribe();
      // }

      // find if the plug is on then send user_online;
      // and if the line is on then send signal_lineon;
      if (isElectron() || this.store.getState().plugON) {
        this.store.dispatch(this.wsActions.setPlugOn(true));
      }
      if (this.store.getState().showLineOnPopUp) {
        this.store.dispatch(
          this.wsActions.setLineOn({
            groupid: this.store.getState().showLineOnPopUp,
          })
        );
      }
      this.socketReconnected = false;
    });

    this.socket.on("disconnect", (err) => {
      console.log("disconnected", err);
      if (new Date().getTime() - this.lastReconnectionTime < 2000) return;
      this.lastReconnectionTime = new Date().getTime();
      this.reconnect();
    });
    this.socketReconnected = true;
    this.handlerCreated = false;
    console.log(this.socket);
  }

  isConnected() {
    return this.socket.connected;
  }

  disconnect() {
    return new Promise(async (res, rej) => {
      try {
        await this.store.dispatch(this.wsActions.setPlugOff());
        await this.socket.disconnect();
        this.handlerCreated = false;
        res();
      } catch (e) {
        rej(e);
      }
    });
  }

  async subscribe(type) {
    switch (type) {
      case "groups": {
        this.groupHandler.subscribe();

        return;
      }

      case "users": {
        this.userHandler.subscribe();

        return;
      }

      case "signals":
        this.signalHandler.subscribe();
        return;

      default:
        return;
    }
  }

  async makeRequest(on, type, payload) {
    if (on === "groups") await this.groupHandler.makeRequest(type, payload);
    else if (on === "signals")
      await this.signalHandler.makeRequest(type, payload);
    else if (on === "users" && this.userHandler) {
      await this.userHandler.makeRequest(type);
    }
  }

  async reAuthenticate(accessToken, user) {
    console.log(accessToken);
    this.socket.emit(
      "authenticate",
      { access_token: accessToken },
      (err, response) => {
        console.log(err, response);
        console.log(this.socket, user);
        this.store.dispatch(this.actions.setUserNewAccessToken(user));
        this.socket.auth.token = accessToken;
      }
    );
  }
}
