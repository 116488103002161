// function handleMakeRequestResponse(response, requestType, store, actions) {
//   if (requestType === "createGroup") {
// let onlineInGroup = store.getState().onlineInGroup;
// let userToGroupMap = store.getState().userToGroupMap;
// response.forEach((element) => {
//   onlineInGroup[element.groupid] = [];
//   element.members.forEach((user) => {
//     if (userToGroupMap[`${user.uid}`] === undefined) {
//       userToGroupMap[`${user.uid}`] = {};
//     }
//     userToGroupMap[`${user.uid}`] = {
//       ...userToGroupMap[`${user.uid}`],
//       [element.groupid]: true,
//     };
//   });
// });
// store.dispatch(
//   actions.addGroupAction([response, ...store.getState().groups])
// );
//   }
//   if (requestType === "sendMessage") {
//     // add message in store;
//   }
// }

export class PlugWsGroupsHandler {
  constructor(socket, store, actions) {
    this.socket = socket;
    this.store = store;
    this.actions = actions;
    this.groupSubscribed = false;
  }
  async subscribe() {
    return new Promise((res, rej) => {
      if (!this.groupSubscribed) {
        this.socket.on("group_details", ({ type, userid, group }) => {
          console.log("group_details", type, userid, group);
          if (type === "createGroup") {
            this.store.dispatch(this.actions.newGroup("created"));
            setTimeout(() => {
              this.store.dispatch(this.actions.newGroup(null));
            }, 1000);
          }
          if (type === "updateGroup") {
            this.store.dispatch(this.actions.newGroup("updated"));
            setTimeout(() => {
              this.store.dispatch(this.actions.newGroup(null));
            }, 1000);
          }
          if (type === "deleteGroup") {
            let groups = this.store.getState().groups;
            let lineOnInGroup = this.store.getState().showLineOnPopUp;
            if (group.groupid === lineOnInGroup) {
              this.store.dispatch(this.actions.newGroup("deleted"));
              setTimeout(() => {
                this.store.dispatch(this.actions.newGroup(null));
              }, 1000);
            } else {
              groups = groups.filter((obj) => {
                return obj.groupid !== group.groupid;
              });
              this.store.dispatch(this.actions.addGroupAction(groups));
            }
          }
        });
        this.socket.on("group_message", ({ groupid, payload, userid }) => {
          console.log("group_message", groupid, payload, userid);
          let message = {};
          let chatBoxOnInGroup = this.store.getState().showChatBox;
          let groups = this.store.getState().groups;
          let lineOnStatus = this.store.getState().lineOnStatus;
          Object.keys(payload).forEach((key) => {
            message = payload[key];
          });

          if (this.store.getState().groupsChats?.[groupid]) {
            this.store.dispatch(
              this.actions.newGroupChat({
                groupid: [groupid],
                message: [message],
                oldMessage: [],
              })
            );
          }
          console.log(this.store.getState().groupsChats);
          groups.sort((a, b) => {
            let a_lineOn = Object.keys(lineOnStatus[a.groupid]).length;
            let b_lineOn = Object.keys(lineOnStatus[b.groupid]).length;
            if (a_lineOn && b_lineOn) return 0;
            else if (a_lineOn) return -1;
            else if (b_lineOn) return 1;
            else if (a.groupid === groupid) return -1;
            else if (b.groupid === groupid) return 1;
          });

          this.store.dispatch(
            this.actions.setNewMsgInGroup({ [groupid]: true })
          );
          this.store.dispatch(this.actions.addGroupAction(groups));

          this.store.dispatch(this.actions.setNewMsg(groupid));
          setTimeout(() => {
            this.store.dispatch(this.actions.setNewMsg(false));
          }, 1000);
        });
      }
      // subscribing to the group to get the users group details.
      this.socket.emit(
        "subscribe",
        {
          handlerType: "groups",
        },
        async (error, response) => {
          // console.log(error, response);
          this.groupSubscribed = true;
          let userToGroupMap = {};
          let onlineInGroup = {};

          let lineOnTime = {};
          let groupLocalStorageLineOnTime = localStorage.getItem(
            "plugApp_lineon_time"
          );

          if (groupLocalStorageLineOnTime === null) {
            groupLocalStorageLineOnTime = {};
            response.forEach((ele) => {
              groupLocalStorageLineOnTime[ele.groupid] = 0;
            });

            localStorage.setItem(
              "plugApp_lineon_time",
              JSON.stringify(groupLocalStorageLineOnTime)
            );
          } else {
            groupLocalStorageLineOnTime = JSON.parse(
              groupLocalStorageLineOnTime
            );
            response.forEach((ele) => {
              if (!groupLocalStorageLineOnTime[ele.groupid])
                groupLocalStorageLineOnTime[ele.groupid] = 0;
            });
          }

          if (response.length === 0) {
            this.store.dispatch(this.actions.groupExist(false));
          } else {
            response.forEach((element) => {
              onlineInGroup[element.groupid] = [];
              lineOnTime[element.groupid] = {
                lineOnTime: groupLocalStorageLineOnTime[element.groupid],
                created_at: element.created_at
                  ? element.created_at
                  : element.updated_at,
              };

              element.members.forEach((user) => {
                if (userToGroupMap[`${user.uid}`] === undefined) {
                  userToGroupMap[`${user.uid}`] = {};
                }
                userToGroupMap[`${user.uid}`] = {
                  ...userToGroupMap[`${user.uid}`],
                  [element.groupid]: true,
                };
              });
            });
            // console.log(lineOnTime);
            response.sort((a, b) => {
              if (
                lineOnTime[b.groupid].lineOnTime >
                lineOnTime[a.groupid].lineOnTime
              )
                return 1;
              else if (
                lineOnTime[b.groupid].lineOnTime <
                lineOnTime[a.groupid].lineOnTime
              )
                return -1;
              else
                return (
                  lineOnTime[b.groupid].created_at -
                  lineOnTime[a.groupid].created_at
                );
            });

            this.store.dispatch(this.actions.setUserToGroupMap(userToGroupMap));
            this.store.dispatch(this.actions.setOnlineInGroup(onlineInGroup));
            // this.store.dispatch(this.actions.newGroupChat(onlineInGroup));

            this.store.dispatch(this.actions.setLatestLineonTime(lineOnTime));
            this.store.dispatch(this.actions.addGroupAction(response));
            res();
          }
        }
      );
    });
  }
  makeRequest(requestType, payload) {
    return new Promise((res, rej) => {
      this.socket.emit(
        "makeRequest",
        {
          handlerType: "groups",
          data: {
            request: requestType,
            payload: payload,
          },
        },
        (error, response) => {
          console.log(requestType, error, response);

          if (error) {
            rej();
          } else {
            this.store.dispatch(this.actions.showCreateGroup(false));
            res();
          }
          // handleMakeRequestResponse(
          //   response,
          //   requestType,
          //   this.store,
          //   this.actions
          // );
        }
      );
    });
  }
}
