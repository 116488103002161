import React, { useState } from "react";
import "../CreateGroup/CreateGroup.css";

import {
  CrossLogo,
  ArrowLogo,
  CancelLogo,
  cubeLogo,
} from "../../../assets/index.js";
import { connect } from "react-redux";
import { showNewConversation } from "../../../Redux/Actions/Action";
import { Avatar } from "antd";
import { generateId, hash } from "../../../logic/common/Utils";
import { createGroup } from "../../../Redux/Actions/WsActions";
const regexEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",

  "#ffbf9f",
  "#f1a200",
];

function NewConversation(props) {
  const [groupName, setName] = useState("");
  const [userEmail, setEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const handleSearchUser = (value) => {
    setEmail(value);
    setSelectedUser("");
  };

  const handleAddUser = (email) => {
    setSelectedUser(email);

    // console.log(selectedUser);
  };

  const handleCreateNewConversation = () => {
    let payload = {};
    payload.groupid = "group-" + generateId(16);
    payload.name = selectedUser.split("@")[0];
    payload.members = [];
    payload.conversation = true;
    payload.members.push({
      email: selectedUser.toLowerCase(),
      name: selectedUser.split("@")[0],
    });
    payload.members.push({
      email: props.user.email,
      name: props.user.displayName,
    });

    // console.log(payload);
    props.createGroup(payload);
    setName("");
    setEmail("");
    setSelectedUser("");
    props.closeNewConversationAction();
  };

  const removeUserEmail = () => {
    setSelectedUser("");
  };
  return (
    <div className="modal_box" style={{ display: "flex" }}>
      <div className="modal_container">
        <CrossLogo
          className="modal_crg_cross_logo"
          onClick={() => {
            props.closeNewConversationAction();
          }}
        />

        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "30px",
              fontWeight: "bolder",
              textAlign: "center",
              marginTop: "60px",
            }}
          >
            New Conversation
          </h2>
          <div className="modal_crg_add_box">
            <div className="modal_crg_add">
              <input
                type="text"
                placeholder="search by Email or Name"
                value={userEmail}
                onChange={(e) => {
                  handleSearchUser(e.target.value);
                }}
                style={{ maxWidth: "60%" }}
              />
              <div
                style={{
                  overflowY: "auto",
                  width: "60%",
                  maxHeight: "65%",
                  paddingRight: "10px",
                }}
              >
                {/* userlist map need will be here with key={`user-li-${idx}`} */}
                {userEmail.match(regexEmail) ? (
                  <div className="search_user_row">
                    <Avatar
                      style={{
                        borderRadius: "50%",
                        padding: "2px",
                        border: "solid 3px  rgba(255,255,255,0)",
                        outline: "2px solid  rgba(255,255,255,0)",
                        outlineOffset: "3px",
                        backgroundColor:
                          colorList[hash(userEmail.split("@")[0]) % 11],
                        cursor: "pointer",
                        fontSize: "20px",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      {userEmail[0].toUpperCase()}
                    </Avatar>
                    <p
                      style={{
                        marginBottom: "0px",
                        flex: 1,
                        marginLeft: "5px",
                        width: "60%",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontWeight: "bolder",
                      }}
                    >
                      {userEmail}
                    </p>

                    {selectedUser ? (
                      <CancelLogo
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          removeUserEmail();
                        }}
                      />
                    ) : (
                      <button
                        type="text"
                        className="search_user_add_button"
                        onClick={() => {
                          handleAddUser(userEmail);
                        }}
                      >
                        + Add
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <button
            style={{
              backgroundColor: "#f1a200",
              border: "none",
              outline: "none",
              color: "white",
              padding: "10px 25px",
              fontSize: "18px",
              borderRadius: "15px",
              cursor: "pointer",
              margin: "20px 15px",
              opacity: !selectedUser ? 0.4 : 1,
              position: "absolute",
              right: "10px",
              bottom: "0px",
            }}
            onClick={() => {
              if (!selectedUser) return;
              handleCreateNewConversation();
            }}
          >
            Create New Conversation
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  closeNewConversationAction: () => dispatch(showNewConversation(false)),
  // makeRequest: (type, payload) => dispatch(makeRequest(type, payload)),
  createGroup: (payload) => {
    dispatch(createGroup(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(NewConversation);
