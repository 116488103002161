import React, { useEffect } from "react";
import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { getDownloadableUrl } from "../../../Redux/Actions/firebaseActions";
import {
  LoadingOutlined,
  PlayCircleFilled,
  PlayCircleTwoTone,
  setTwoToneColor,
} from "@ant-design/icons";
import "./ChatAudio.css";
setTwoToneColor("#aaa");
export default function ChatAudio(props) {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDownloadUrl = async () => {
    setLoading(true);
    getDownloadableUrl(props.url)
      .then((url) => {
        setUrl(url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      {loading ? <LoadingOutlined /> : null}

      {!url ? (
        <div
          style={{
            display: "flex",
            width: "300px",
            height: "50px",
            backgroundColor: "#ddd",
            borderRadius: "20px",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <PlayCircleTwoTone
            style={{
              fontSize: "30px",
              color: "black",
              marginLeft: "10px",
            }}
            onClick={() => {
              handleDownloadUrl();
            }}
          />
          <p
            style={{
              marginBottom: "0px",
              marginLeft: "20px",
              fontWeight: "bolder",
            }}
          >
            play voice note
          </p>
        </div>
      ) : (
        <audio src={url} controls />
      )}

      {/* {url ? null : (
        <DownloadOutlined
          className="download_media_url"
          style={{ fontSize: "25px" }}
          onClick={() => {
            handleDownloadUrl();
          }}
        />
      )} */}
    </div>
  );
}
