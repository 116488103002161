export const addGroupAction = (payload) => {
  return {
    type: "ADD_GROUP",
    payload: payload,
  };
};

export const plugON = (payload) => {
  return {
    type: "PLUG_ON",
    payload: payload,
  };
};

export const setUserEamils = (payload) => {
  return {
    type: "SET_USER_EMAIL",
    payload: payload,
  };
};

export const groupExist = (payload) => {
  return {
    type: "GROUP_EXIST",
    payload: payload,
  };
};
export const showCreateGroup = (payload) => {
  return {
    type: "SHOW_CREATE_GROUP",
    payload: payload,
  };
};

export const showNewConversation = (payload) => {
  return {
    type: "SHOW_NEW_CONVERSATION",
    payload: payload,
  };
};

export const showGroupSettings = (payload) => {
  return {
    type: "SHOW_GROUP_SETTINGS",
    payload: payload,
  };
};

export const showPopUp = (payload) => {
  return {
    type: "SHOW_POP_UP",
    payload: payload,
  };
};

export const newGroup = (payload) => {
  return {
    type: "NEW_GROUP",
    payload: payload,
  };
};

export const userLogin = (payload) => {
  return {
    type: "USER_LOGGED",
    payload: payload,
  };
};

export const showLineOnPopUp = (payload) => {
  return {
    type: "SHOW_LINE_ON_POP_UP",
    payload: payload,
  };
};

export const activateGroup = (payload) => {
  return {
    type: "ACTIVATE_GROUP",
    payload: payload,
  };
};

export const setOnlineStatus = (payload) => {
  return {
    type: "SET_ONLINE_STATUS",
    payload: payload,
  };
};

export const setUserToGroupMap = (payload) => {
  return {
    type: "SET_USER_TO_GROUP_MAP",
    payload: payload,
  };
};

export const setOnlineInGroup = (payload) => {
  return {
    type: "SET_ONLINE_IN_GROUP",
    payload: payload,
  };
};

export const newGroupChat = (payload) => {
  return {
    type: "CHAT_IN_GROUP",
    payload: payload,
  };
};

export const showChatBoxAction = (payload) => {
  return {
    type: "SHOW_CHAT_BOX",
    payload: payload,
  };
};

export const lineOnStatus = (payload) => {
  return {
    type: "SET_LINEON_STATUS_GROUP",
    payload: payload,
  };
};

export const lineOnGroups = (payload) => {
  return {
    type: "SET_LINEON_GROUPS",
    payload: payload,
  };
};

export const lineOffGroups = (payload) => {
  return {
    type: "SET_LINEOFF_GROUPS",
    payload: payload,
  };
};

export const setBusyStatus = (payload) => {
  return {
    type: "SET_BUSY_STATUS",
    payload: payload,
  };
};

export const janusRoomJoinning = (payload) => {
  return {
    type: "JANUS_ROOM_JOINED",
    payload: payload,
  };
};
export const userPlugginOn = (payload) => {
  return {
    type: "PLUGGING_ON",
    payload: payload,
  };
};

export const setLatestLineonTime = (payload) => {
  return {
    type: "SET_LATEST_LINEON_TIME",
    payload: payload,
  };
};

export const setUserNewAccessToken = (payload) => {
  return {
    type: "SET_USER_NEW_TOKEN",
    payload: payload,
  };
};

export const setNotification = (payload) => {
  return {
    type: "SET_NOTIFICATION",
    payload: payload,
  };
};
export const setNewMsg = (payload) => {
  return {
    type: "SET_NEW_MSG",
    payload: payload,
  };
};
export const setNewMsgInGroup = (payload) => {
  return {
    type: "SET_NEW_MSG_IN_GROUP",
    payload: payload,
  };
};
