import { getUserAttribute } from "../../logic/common/Utils";

const initialState = {
  logged: false,
  plugON: false,
  bgColor: "rgba(241, 162, 0,0.3)",
  showCreateGroup: false,
  showNewConversationPopUp: false,
  showGroupSettings: false,
  showPopUp: false,
  user: null,
  groups: [],
  socketConnected: false,
  janusConnected: false,
  janusRoomJoinning: null,
  userPlugginOn: false,
  subscribing: false,
  showLineOnPopUp: null,
  activeGroup: { groupName: "", members: [], groupid: "" },
  onlineStatus: {},
  lineOnStatus: {},
  busyStatus: {},
  latestLineOnTime: {},
  userToGroupMap: {},
  onlineInGroup: {},
  lineOnGroups: {},
  groupsChats: {},
  showChatBox: false,
  notification: {},
  userEmails: [],
  newMessageInGroup: {},
  newMessage: false,
  newGroup: false,
  groupExist: true,
  hideLoadingChat: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGGED": {
      return {
        ...state,
        logged: action.payload.logged,
        bgColor: action.payload.bgColor,
        user: {
          ...(action.payload.user || {}),
          displayName: getUserAttribute(
            action.payload.user || {},
            "displayName"
          ),
          photoURL: getUserAttribute(action.payload.user || {}, "photoURL"),
          email: getUserAttribute(action.payload.user || {}, "email"),
          phoneNumber: getUserAttribute(
            action.payload.user || {},
            "phoneNumber"
          ),
        },
      };
    }
    case "GROUP_EXIST": {
      return {
        ...state,
        groupExist: action.payload,
      };
    }
    case "PLUG_ON": {
      return {
        ...state,
        plugON: action.payload.plug,
        bgColor: action.payload.bgColor,
      };
    }
    case "NEW_GROUP": {
      return {
        ...state,
        newGroup: action.payload,
      };
    }
    case "SHOW_CREATE_GROUP": {
      return {
        ...state,
        showCreateGroup: action.payload,
      };
    }
    case "SHOW_NEW_CONVERSATION": {
      return {
        ...state,
        showNewConversationPopUp: action.payload,
      };
    }
    case "SET_USER_EMAIL": {
      return {
        ...state,
        userEmails: action.payload,
      };
    }
    case "SET_NEW_MSG_IN_GROUP": {
      return {
        ...state,
        newMessageInGroup: {
          ...state.newMessageInGroup,
          ...action.payload,
        },
      };
    }
    case "SET_NEW_MSG": {
      return {
        ...state,
        newMessage: action.payload,
      };
    }
    case "SHOW_GROUP_SETTINGS": {
      return {
        ...state,
        showGroupSettings: action.payload,
      };
    }
    case "SHOW_POP_UP": {
      return {
        ...state,
        showPopUp: action.payload,
      };
    }
    case "ADD_GROUP": {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case "PLUGGING_ON": {
      return {
        ...state,
        userPlugginOn: action.payload,
      };
    }
    case "JANUS_ROOM_JOINED": {
      return {
        ...state,
        janusRoomJoinning: action.payload,
      };
    }
    case "JANUS_CONNECTED": {
      return {
        ...state,
        janusConnected: action.payload,
      };
    }
    case "SOCKET_CONNECTED": {
      return {
        ...state,
        socketConnected: action.payload,
      };
    }
    case "SET_USER_NEW_TOKEN": {
      return {
        ...state,
        user: action.payload,
      };
    }
    case "SET_NOTIFICATION": {
      return {
        ...state,
        notification: action.payload,
      };
    }
    case "SUBSCRIBING": {
      return {
        ...state,
        subscribing: action.payload,
      };
    }
    case "SHOW_LINE_ON_POP_UP": {
      return {
        ...state,
        showLineOnPopUp: action.payload,
      };
    }
    case "SHOW_CHAT_BOX": {
      return {
        ...state,
        showChatBox: action.payload,
      };
    }
    case "ACTIVATE_GROUP": {
      return {
        ...state,
        activeGroup: action.payload,
      };
    }
    case "SET_ONLINE_STATUS": {
      return {
        ...state,
        onlineStatus: action.payload,
      };
    }
    case "SET_USER_TO_GROUP_MAP": {
      return {
        ...state,
        userToGroupMap: action.payload,
      };
    }
    case "SET_HIDE_CHATS_LOADING": {
      return {
        ...state,
        hideLoadingChat: {
          ...state.hideLoadingChat,
          [action.payload.groupid]: true,
        },
      };
    }
    case "SET_ONLINE_IN_GROUP": {
      return {
        ...state,
        onlineInGroup: { ...state.onlineInGroup, ...action.payload },
      };
    }
    case "SET_LINEON_STATUS_GROUP": {
      return {
        ...state,
        lineOnStatus: action.payload,
      };
    }
    case "SET_LATEST_LINEON_TIME": {
      return {
        ...state,
        latestLineOnTime: action.payload,
      };
    }
    case "SET_BUSY_STATUS": {
      return {
        ...state,
        busyStatus: { ...state.busyStatus, ...action.payload },
      };
    }

    case "SET_LINEON_GROUPS": {
      return {
        ...state,
        lineOnGroups: { ...state.lineOnGroups, [action.payload]: true },
      };
    }
    case "SET_LINEOFF_GROUPS": {
      return {
        ...state,
        lineOnGroups: { ...state.lineOnGroups, [action.payload]: false },
      };
    }
    case "CHAT_IN_GROUP": {
      return {
        ...state,
        groupsChats: {
          ...state.groupsChats,
          [action.payload.groupid]: [
            ...action.payload.message,
            ...(state?.groupsChats?.[action.payload.groupid] ?? []),
            ...action.payload.oldMessage,
          ],
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
