import React, { useEffect } from "react";
import "./Login.css";

import {
  loginBoy,
  loginGirl,
  plugLogo,
  copyRight,
  CancelLogo,
  windows,
  linux,
  macOs,
} from "../../../assets/index.js";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  userLogin,
  showPopUp,
  setUserNewAccessToken,
} from "../../../Redux/Actions/Action";
import {
  initializeFirebaseClient,
  setUser,
} from "../../../Redux/Actions/firebaseActions";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import * as firebaseui from "firebaseui";

import { reAuthenticate } from "../../../Redux/Actions/WsActions";
import {
  getOsArray,
  isElectron,
  windowsLink,
  macOsLink,
  linuxLink,
} from "../../../logic/common/Utils";

const firebaseConfig = {
  apiKey: "AIzaSyDC4QFIOaCyQy_15N0bybWlzuTBujZN3Sk",
  authDomain: "plug-app-ebbab.firebaseapp.com",
  databaseURL:
    "https://plug-app-ebbab-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "plug-app-ebbab",
  storageBucket: "plug-app-ebbab.appspot.com",
  messagingSenderId: "1499255857",
  appId: "1:1499255857:web:c04583b5a5a7513975b110",
  measurementId: "G-3VT9YSZ3KZ",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
let ui = new firebaseui.auth.AuthUI(firebase.auth());

function Login(props) {
  const showPopUp = props.showPopUp;
  const domain = window.location.hostname;

  const navigate = useNavigate();

  useEffect(() => {
    props.firebaseInit(firebase);
    // console.log(window.location.hostname);
    // console.log(firebase.database());
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);

      if (user) {
        props.loginAction({
          logged: true,
          bgColor: "rgba(0,0,0,0.3)",
          user: user.auth.currentUser,
        });

        // the acces token will expire after 1h.
        // we will need to get the new token using getIdTokenResult()
        // and update the socket for the same.
        function updateAccessToken(expirationTime) {
          if (expirationTime < 300000) {
            expirationTime = 300000;
          }
          setTimeout(async () => {
            let result = await user.getIdTokenResult(true); // true means forcefully refresh the token.
            // console.log(result);

            updateAccessToken(
              new Date(result.expirationTime).getTime() - new Date().getTime()
            );
            // now update the socket for new jwt token.

            let newUser = await firebase.auth().currentUser._delegate;
            // console.log(newUser);
            props.reAuthenticate(result.token, newUser);

            // console.log(props.user);
          }, expirationTime - 300000); // before 5 min of expiration of jwt token forcefully update.
        }

        // console.log(
        //   user.auth.currentUser.stsTokenManager,
        //   user.auth.currentUser.stsTokenManager.expirationTime -
        //     new Date().getTime()
        // );

        updateAccessToken(
          user.auth.currentUser.stsTokenManager.expirationTime -
            new Date().getTime()
        );

        props.popUpAction(false);

        navigate("/home", { replace: true });
      } else {
        console.log("user logged out");

        props.loginAction({
          logged: false,
          bgColor: "rgb(241, 162, 0,0.3)",
          user: null,
        });
        navigate("/", { replace: true });
        ui.start("#firebaseui-auth-container", {
          signInFlow: "popup",
          signInOptions: [
            {
              provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,

              customParameters: {
                // Forces account selection even when one account
                // is available.
                prompt: "select_account",
              },
            },
            {
              provider: "microsoft.com",

              customParameters: {
                prompt: "select_account",
              },
            },
          ],
          callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
              // console.log(authResult, redirectUrl);
              // User successfully signed in.
              // Return type determines whether we continue the redirect automatically
              // or whether we leave that to developer to handle.
              return true;
            },
          },
        });
      }
    });
  }, []);
  return (
    <React.Fragment>
      {/* <div className="login_girl_box">
        <img src={loginGirl} className="login_girl_img" />
      </div>
      <div className="login_boy_box">
        <img src={loginBoy} className="login_boy_img" />
      </div> */}
      <div className="login_plugLogo">
        <img src={plugLogo}></img>

        {!isElectron() && domain != "web.plugapp.net" && getOsArray() ? (
          getOsArray().map((os, idx) => {
            if (idx === 0) {
              return (
                <a
                  key={`anchor-${idx}`}
                  href={
                    os === "Windows"
                      ? windowsLink
                      : os === "Linux"
                      ? linuxLink
                      : macOsLink
                  }
                  className="download_os"
                  download
                >
                  Download Plug for {os}
                </a>
              );
            } else {
              return (
                <div
                  key={`anchor-${idx}`}
                  style={{
                    marginBottom: "30px",
                    display: "flex",
                    alignItems: "center",

                    fontWeight: "bolder",
                    cursor: "pointer",
                    fontSize: "17px",
                  }}
                >
                  <img
                    src={
                      os === "Windows"
                        ? windows
                        : os === "Linux"
                        ? linux
                        : macOs
                    }
                    style={{
                      height: "32px",
                      width: "32px",
                      marginRight: "15px",
                    }}
                  />
                  <a
                    href={
                      os === "Windows"
                        ? windowsLink
                        : os === "Linux"
                        ? linuxLink
                        : macOsLink
                    }
                    download
                    style={{ color: "#f1a200" }}
                  >
                    Download for {os}
                  </a>
                </div>
              );
            }
          })
        ) : (
          <button
            onClick={() => {
              props.popUpAction(true);
            }}
          >
            Login with your work email
          </button>
        )}
      </div>
      <div
        style={{
          display: showPopUp ? "block" : "none",
        }}
        className="login_pop_up"
      >
        <CancelLogo
          style={{
            position: "absolute",
            top: "15px",
            right: "15px",
            fontSize: "20px",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => {
            props.popUpAction(false);
          }}
          className="login_cancel_btn"
        />
        <div
          id="firebaseui-auth-container"
          className="login_ui"
          style={{ cursor: "pointer" }}
        ></div>
      </div>

      <div className="login_copyright">
        <img src={copyRight}></img>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  loginAction: (payload) => dispatch(userLogin(payload)),
  popUpAction: (payload) => dispatch(showPopUp(payload)),
  firebaseInit: (payload) => dispatch(initializeFirebaseClient(payload)),

  reAuthenticate: (payload, user) => {
    console.log("this is user", user);
    dispatch(reAuthenticate(payload, user));
  },
  // setUserNewAccessToken: (payload) => {
  //   dispatch(setUserNewAccessToken(payload));
  // },
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
