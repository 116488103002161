import React, { useState, useEffect } from "react";
import { Row, Col, Avatar, notification, Tooltip } from "antd";
import "./Group.css";

import {
  GroupLogo,
  OnlineLogo,
  SettingLogo,
  TalkLogo,
  soundLogo,
  chatLogo,
  LoaderLogo,
} from "../../../assets/index.js";
import {
  setNewMsgInGroup,
  showChatBoxAction,
  showGroupSettings,
} from "../../../Redux/Actions/Action";
import { connect } from "react-redux";
import { joinJanusRoom } from "../../../Redux/Actions/JanusClientActions.js";

import { trackEventActions } from "../../../Redux/Actions/firebaseActions";

import { activateGroup } from "../../../Redux/Actions/Action";
import { setLineOn, deleteGroup } from "../../../Redux/Actions/WsActions";
import { hash } from "../../../logic/common/Utils";
import { getGroupMessageHistory } from "../../../Redux/Actions/firebaseActions";
import Notification from "../Notification/Notification";

function Group(props) {
  // const [online, setOnline] = useState(props.talking);
  // const [user, setUser] = useState(props.user);

  const talkStyle = {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    padding: "2px",
    border: "solid 3px #00bf19",
    outline: "2px solid  #00bf1921",
    outlineOffset: "3px",
    backgroundColor: "#f1a200",
    cursor: "pointer",
    fontSize: "25px",
  };
  const nonTalkStyle = {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    padding: "2px",
    border: "solid 3px  rgba(255,255,255,0)",
    outline: "2px solid  rgba(255,255,255,0)",
    outlineOffset: "3px",
    backgroundColor: "#f1a200",
    cursor: "pointer",
    fontSize: "25px",
    lineHeight: "2",
  };

  const onlineStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#00bf19",
    border: "4px solid #f2fcf3",
    position: "absolute",
    right: "10px",
  };
  const busyStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#d63131",
    border: "4px solid #fff0f0",
    position: "absolute",
    right: "10px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bolder",
    lineHeight: "3",
  };

  const colorList = [
    "#f56a90",
    "#7265e6",
    "#ffbf9f",
    "#00a2ae",
    "#f1a200",
    "#00a2ae",
    "#7265e6",
    "#f56a90",
    "#ffbf9f",
    "#ffbf9f",
    "#f1a200",
  ];

  const handleDeleteGroup = () => {
    let payload = {};
    payload.groupid = props.groupid;
    props
      .deleteGroup(payload)
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Row
      style={{
        marginBottom: "30px",
        marginRight: "20px",
        // paddingBottom: "10px",
        // borderBottom: "1px solid #eee",
      }}
      className="plugApp_group"
    >
      <Col span={24}>
        <Row align="middle" style={{ marginBottom: "10px" }}>
          <Col flex={1}>
            <Row align="middle">
              {props.plug &&
              Object.keys(props.lineOnStatus?.[props?.groupid] ?? {}).length ? (
                <span
                  style={{
                    height: "15px",
                    width: "15px",
                    backgroundColor: "#00bf19",
                    borderRadius: "50%",
                    marginRight: "20px",
                  }}
                ></span>
              ) : null}
              <h3
                className="group_heading"
                style={{
                  color:
                    props.plug &&
                    Object.keys(props.lineOnStatus?.[props?.groupid] ?? {})
                      .length
                      ? "#00bf19"
                      : "black",
                }}
              >
                {props.conversation
                  ? props.members.map((obj) => {
                      if (obj.email !== props.user.email)
                        return obj.displayName;
                    })
                  : props.groupName}
              </h3>
              <span style={{ display: "flex", marginLeft: "20px" }}>
                <span className="statusLogo">
                  <Tooltip
                    placement="bottom"
                    title={
                      <div>
                        <p style={{ color: "#121212", fontWeight: "bolder" }}>
                          Group Members
                        </p>
                        {props.members
                          .slice(0, 5)
                          .concat(
                            props.members.length > 5
                              ? [
                                  {
                                    displayName: `+${
                                      props.members.length - 5
                                    } more`,
                                  },
                                ]
                              : []
                          )
                          .map((obj, idx) => {
                            return (
                              <p
                                style={{ color: "#121212" }}
                                key={`list-tooptip-${idx}`}
                              >
                                {obj?.displayName ?? "@someone"}
                              </p>
                            );
                          })}
                      </div>
                    }
                    color={"#f7f7f7"}
                  >
                    <GroupLogo style={{ height: "22px", width: "22px" }} />
                  </Tooltip>
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props.members.length}
                  </span>
                </span>
                <span className="statusLogo">
                  <Tooltip
                    placement="bottom"
                    title={
                      <div>
                        <p style={{ color: "#121212", fontWeight: "bolder" }}>
                          Members Online
                        </p>
                        {props?.onlineInGroup?.[props?.groupid]?.map(
                          (uid, idx) => {
                            return (
                              <p
                                style={{ color: "#121212" }}
                                key={`list-tooptip-${idx}`}
                              >
                                {props.onlineStatus?.[uid]?.profile
                                  ?.displayName === ""
                                  ? props.onlineStatus?.[uid]?.profile?.email
                                  : props.onlineStatus?.[uid]?.profile
                                      ?.displayName}
                              </p>
                            );
                          }
                        )}
                      </div>
                    }
                    color={"#f7f7f7"}
                  >
                    <OnlineLogo
                      style={{
                        height: "22px",
                        width: "22px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>

                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {props?.onlineInGroup?.[props?.groupid]?.length}
                  </span>
                </span>
                <span className="statusLogo" style={{ marginRight: "20px" }}>
                  <Tooltip
                    placement="bottom"
                    color={"#f7f7f7"}
                    title={
                      <div>
                        <p style={{ color: "#121212", fontWeight: "bolder" }}>
                          Members Talking
                        </p>
                        {Object?.keys(
                          props.lineOnStatus?.[props?.groupid] ?? {}
                        )?.length
                          ? Object?.keys(
                              props.lineOnStatus?.[props?.groupid]
                            )?.map((uid, idx) => {
                              return (
                                <p
                                  style={{ color: "#121212" }}
                                  key={`list-tooptip-${idx}`}
                                >
                                  {props.onlineStatus?.[uid]?.profile
                                    ?.displayName ??
                                    props.onlineStatus?.[uid]?.profile?.email}
                                </p>
                              );
                            })
                          : null}
                      </div>
                    }
                  >
                    <TalkLogo
                      style={{
                        height: "22px",
                        width: "22px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>

                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {
                      Object?.keys(props.lineOnStatus?.[props?.groupid] ?? {})
                        ?.length
                    }
                  </span>
                </span>
              </span>
            </Row>
          </Col>
          <Col>
            <Row align="middle">
              {props.conversation ? (
                <div className="group_setting">
                  <SettingLogo
                    style={{ cursor: "pointer" }}
                    className="groupSetting_logo"
                  />
                  <div className="group_setting_dropdown">
                    <p
                      onClick={() => {
                        handleDeleteGroup();
                      }}
                    >
                      Delete Group
                    </p>
                  </div>
                </div>
              ) : (
                <div className="group_setting">
                  <SettingLogo
                    style={{ cursor: "pointer" }}
                    className="groupSetting_logo"
                  />
                  <div className="group_setting_dropdown">
                    <p
                      onClick={() => {
                        props.activateGroup({
                          groupName: props.groupName,
                          members: props.members,
                          groupid: props.groupid,
                        });
                        props.showSettings();
                      }}
                    >
                      group setting
                    </p>
                  </div>
                </div>
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ justifyContent: "space-between" }} gutter={[2, 20]}>
          <Col
            md={18}
            xs={24}
            style={{
              backgroundColor:
                props.plug &&
                Object.keys(props.lineOnStatus?.[props?.groupid] ?? {}).length
                  ? "rgba(0, 191, 25, 0.05)"
                  : "#f7f7f7",

              borderRadius: "16px",
              marginLeft: "2px",
              border:
                props.plug &&
                Object.keys(props.lineOnStatus?.[props?.groupid] ?? {}).length
                  ? "solid 2px #00bf19"
                  : "none",
            }}
            className="group_user_container"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "100%",

                overflowX: "auto",
                overflowY: "hidden",
              }}
              className="group_hide_scrollbar"
            >
              {props.plugON &&
                props.members.map((obj, index) => {
                  return (
                    <span key={`user-li-${index}`} className="user_wrapper">
                      {props.plugON &&
                      props.notification?.[props?.groupid] === obj?.uid &&
                      props.notification?.[props?.groupid] !==
                        props.user.uid ? (
                        <Notification
                          displayName={obj?.displayName}
                          groupName={props.groupName}
                          groupid={props.groupid}
                        />
                      ) : null}
                      <Tooltip
                        title={
                          props.plugON &&
                          props.lineOnStatus?.[props?.groupid]?.[obj?.uid]
                            ? "this person is talking in this group"
                            : props.plugON && props.busyStatus?.[obj?.uid]
                            ? "this person is busy on another call "
                            : props.plugON &&
                              props.onlineStatus?.[obj?.uid]?.online
                            ? "this person is available on plug to talk"
                            : null
                        }
                        color={"#f1a200"}
                      >
                        {obj.photoURL ? (
                          <img
                            style={{
                              borderRadius: "50%",
                              padding: "2px",
                              border:
                                props.plug &&
                                props.lineOnStatus?.[props?.groupid]?.[obj?.uid]
                                  ? "solid 2px #00bf19"
                                  : "solid 2px  rgba(255,255,255,0)",
                              outline:
                                props.plug &&
                                props.lineOnStatus?.[props?.groupid]?.[obj?.uid]
                                  ? "1px solid  #00bf1921"
                                  : "1px solid  rgba(255,255,255,0)",
                              outlineOffset: "3px",

                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                            className="group_user_img"
                            src={obj.photoURL}
                          />
                        ) : (
                          <Avatar
                            style={{
                              borderRadius: "50%",
                              padding: "2px",
                              backgroundClip: "content-box",
                              border:
                                props.plug &&
                                props.lineOnStatus?.[props?.groupid]?.[obj?.uid]
                                  ? "solid 2px #00bf19"
                                  : "solid 2px  rgba(255,255,255,0)",
                              outline:
                                props.plug &&
                                props.lineOnStatus?.[props?.groupid]?.[obj?.uid]
                                  ? "1px solid  #00bf1921"
                                  : "1px solid  rgba(255,255,255,0)",
                              outlineOffset: "3px",
                              backgroundColor:
                                colorList[
                                  hash(obj?.displayName ?? "@someone") % 11
                                ],

                              cursor: "pointer",
                              fontSize: "25px",
                            }}
                            className="group_user_avatar"
                          >
                            {obj?.displayName?.[0]?.toUpperCase() ?? "@"}
                          </Avatar>
                        )}
                      </Tooltip>

                      {props.plugON &&
                      !props.lineOnStatus?.[props?.groupid]?.[obj?.uid] &&
                      props.onlineStatus?.[obj?.uid]?.online ? (
                        props.busyStatus?.[obj?.uid] ? (
                          <span style={busyStyle} key={index}>
                            -
                          </span>
                        ) : (
                          <span style={onlineStyle} key={index}></span>
                        )
                      ) : null}

                      <span className="user_name">
                        {props.user.uid === obj.uid
                          ? "you"
                          : obj?.displayName ?? "@someone"}
                      </span>
                    </span>
                  );
                })}

              {/* {props.members.length > 5 ? (
                <span style={{ fontWeight: "bold" }}>
                  {props.members.length - 6} more...
                </span>
              ) : null} */}
            </div>
          </Col>
          <Col md={1} xs={0}></Col>
          <Tooltip
            placement="bottom"
            title={
              props.plugON
                ? "click on it and start talking to your teammates"
                : null
            }
            color={"#f1a200"}
            style={{ borderRadius: "10px" }}
          >
            <Col
              md={2}
              xs={18}
              className={
                props.plug ? "hoverEffect sound_container" : "sound_container"
              }
              style={{
                opacity: props.plug
                  ? props?.janusRoomJoinning &&
                    props.janusRoomJoinning !== props.groupid
                    ? "0.5"
                    : "1"
                  : "0.5",
                disable: props.plug ? false : true,
                cursor: "pointer",
              }}
              onClick={async () => {
                if (props.plug) {
                  if (props.janusRoomJoinning) {
                    console.log(
                      "room joining process already in room",
                      props.janusRoomJoinning
                    );
                    return;
                  }
                  props.activateGroup({
                    groupName: props.groupName,
                    members: props.members,
                    groupid: props.groupid,
                  });
                  await props.joinJanusRoom(props.groupid, props.displayName);
                  if (!props.groupsChats?.[props.groupid]?.length)
                    props
                      .getMessageHistory(props.groupid)
                      .then(() => {})
                      .catch((e) => {
                        console.log(e);
                      });
                  props
                    .setLineOn({ groupid: props.groupid })
                    .then(() => {})
                    .catch((e) => console.log(e));
                }
              }}
            >
              {props?.janusRoomJoinning &&
              props.janusRoomJoinning === props.groupid ? (
                <LoaderLogo className="spinner" />
              ) : (
                <>
                  <img
                    src={soundLogo}
                    className="soundLogo"
                    draggable="false"
                  />
                  <span className="soundLogoText">Talk</span>
                </>
              )}
            </Col>
          </Tooltip>
          <Col
            md={2}
            xs={4}
            className={
              props.plug ? "hoverEffect sound_container" : "sound_container"
            }
            style={{
              opacity: props.plug ? "1" : "0.5",
              marginRight: "0px",
              cursor: "pointer",
              maxWidth: "100px",
              transform: "scale(0.6)",
            }}
            onClick={() => {
              if (props.plug) {
                props.activateGroup({
                  groupName: props.groupName,
                  members: props.members,
                  groupid: props.groupid,
                });
                if (!props.groupsChats?.[props.groupid]?.length)
                  props
                    .getMessageHistory(props.groupid)
                    .then(() => {})
                    .catch((e) => {
                      console.log(e);
                    });
                props.showChatBoxAction(props.groupid);
                trackEventActions("chaton", {
                  group: props.groupName,
                  groupid: props.groupid,
                });
              }
            }}
          >
            <img src={chatLogo} draggable="false" className="chatLogo" />
            <span className="chatLogoText">Chat</span>
            {props.newMessageInGroup[props.groupid] ? (
              <span
                style={{
                  position: "absolute",
                  height: "25px",
                  width: "25px",
                  borderRadius: "50%",
                  backgroundColor: "#FF4433",
                  top: "-10px",
                  right: "-10px",
                }}
              ></span>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state) => {
  return { ...state };
};

const mapDispatchToProps = (dispatch) => ({
  showSettings: () => dispatch(showGroupSettings(true)),
  joinJanusRoom: (groupid, displayName) =>
    dispatch(joinJanusRoom(groupid, displayName)),
  activateGroup: (payload) => {
    dispatch(activateGroup(payload));
  },
  setLineOn: (payload) => dispatch(setLineOn(payload)),
  showChatBoxAction: (groupid) => {
    dispatch(setNewMsgInGroup({ [groupid]: false }));
    dispatch(showChatBoxAction(groupid));
  },
  getMessageHistory: (groupid) => {
    return dispatch(getGroupMessageHistory(groupid));
  },
  deleteGroup: (payload) => dispatch(deleteGroup(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Group);
