import React, { useEffect } from "react";
import { useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { CrossLogo } from "../../../assets";
import { connect } from "react-redux";
import {
  uploadFileToCloud,
  getDownloadableUrl,
} from "../../../Redux/Actions/firebaseActions";
import { message, Spin } from "antd";
import { sendMessageAction } from "../../../Redux/Actions/WsActions";
import "./RecordView.css";
function RecordView(props) {
  const [start, setStart] = useState(false);
  const [send, setSend] = useState(false);
  const [seconds, setSeconds] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);

  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });

  const handleSendMedia = async (blob) => {
    if (blob.size > 52428800) {
      message.info("audio size more than 50mb");
    }
    let payload = {};
    payload.groupid = props.activeGroup.groupid;
    payload.displayName = props?.user?.displayName;
    try {
      setLoading(true);
      payload.type = "AUDIO";
      const Url = await uploadFileToCloud(blob, "wav");

      payload.url = Url;
      payload.size = blob.size;
      console.log(payload);
      await props.sendMessageAction(payload);
      setLoading(false);
      props.closeRecordPopUp();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let interval;

    if (start) {
      interval = setInterval(() => {
        let count = counter + 1;
        let min_ = Math.floor(count / 60);
        let sec_ = count % 60;

        min_ = String(min_).length == 1 ? `0${min_}` : String(min_);
        sec_ = String(sec_).length == 1 ? `0${sec_}` : String(sec_);
        setCounter(count);
        setMinutes(min_);
        setSeconds(sec_);
      }, 1000);
    }

    if (send) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [start, counter, send]);

  return (
    <div
      className={
        props.lineOnChat
          ? "fileIcon audio_record lineOn_fileIcon"
          : "fileIcon audio_record"
      }
    >
      {/* <div> */}
      <CrossLogo
        style={{
          // alignSelf: "flex-end",
          marginRight: "10px",
          cursor: "pointer",
          display: "block",
          position: "absolute",
          right: "0px",
          top: "0px",
        }}
        onClick={() => {
          props.closeRecordPopUp();
        }}
      />
      <div style={{ marginBottom: "10px" }}>
        <span style={{ fontWeight: "bolder", fontSize: "20px" }}>
          {minutes}
        </span>
        <span style={{ fontWeight: "bolder", fontSize: "20px" }}>:</span>
        <span style={{ fontWeight: "bolder", fontSize: "20px" }}>
          {seconds}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: props.lineOnChat ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <audio
          src={mediaBlobUrl}
          styel={{ backgroundColor: "#f1a200" }}
          controls
          autoPlay
          // style={{ marginBottom: "25px" }}
        />
        {start ? (
          send ? (
            <div>
              <button
                onClick={() => {
                  fetch(mediaBlobUrl)
                    .then((res) => res.blob())
                    .then((res) => {
                      handleSendMedia(res);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
                className="record_btn"
              >
                Send Recording
              </button>
            </div>
          ) : (
            <button
              onClick={() => {
                stopRecording();
                setSend(true);
              }}
              className="record_btn"
            >
              Stop Recording
            </button>
          )
        ) : (
          <button
            onClick={() => {
              startRecording();

              setStart(true);
            }}
            className="record_btn"
          >
            Start Recording
          </button>
        )}
        {loading ? <Spin /> : null}
      </div>
      {/* </div> */}
    </div>
  );
}
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  sendMessageAction: (payload) => {
    return dispatch(sendMessageAction(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RecordView);
