export class PlugWsUsersHandler {
  constructor(socket, store, actions) {
    this.socket = socket;
    this.store = store;
    this.actions = actions;
    this.userSubscribed = false;
  }

  async subscribe() {
    console.log("user subscribed");
    if (!this.userSubscribed) {
      this.socket.on("user_online", async ({ uid }) => {
        console.log("user_online", uid);
        let onlineInGroup = this.store.getState().onlineInGroup;
        let groups = this.store.getState().groups;
        let myUid = this.store.getState().user.uid;

        Object.keys(this.store.getState().userToGroupMap[uid]).forEach(
          (groupid) => {
            onlineInGroup[groupid] = [
              ...new Set([...onlineInGroup[groupid], uid]),
            ];
          }
        );

        groups.forEach((group_obj) => {
          group_obj.members.sort((a, b) => {
            if (a.uid === myUid) return -1;
            if (b.uid === myUid) return 1;
            if (a.uid === uid) return -1;
            if (b.uid === uid) return 1;
            return 0;
          });
        });

        // console.log(onlineInGroup);
        this.store.dispatch(this.actions.setOnlineInGroup(onlineInGroup));
        this.store.dispatch(this.actions.addGroupAction(groups));
        this.store.dispatch(
          this.actions.setOnlineStatus({
            ...this.store.getState().onlineStatus,
            [uid]: {
              ...this.store.getState().onlineStatus[uid],
              online: true,
            },
          })
        );
      });

      this.socket.on("user_offline", async ({ uid }) => {
        console.log("user_offline", uid);
        let onlineInGroup = this.store.getState().onlineInGroup;
        let groups = this.store.getState().groups;
        let myUid = this.store.getState().user.uid;
        Object.keys(this.store.getState().userToGroupMap[uid]).forEach(
          (groupid) => {
            onlineInGroup[groupid] = onlineInGroup[groupid].filter(
              (email) => email != uid
            );
          }
        );

        groups.forEach((group_obj) => {
          group_obj.members.sort((a, b) => {
            if (a.uid === myUid) return -1;
            if (b.uid === myUid) return 1;
            if (a.uid === uid) return 1;
            if (b.uid === uid) return -1;
            return 0;
          });
        });
        // console.log(onlineInGroup);
        this.store.dispatch(this.actions.addGroupAction(groups));

        this.store.dispatch(this.actions.setOnlineInGroup(onlineInGroup));
        this.store.dispatch(
          this.actions.setOnlineStatus({
            ...this.store.getState().onlineStatus,
            [uid]: {
              ...this.store.getState().onlineStatus[uid],
              online: false,
            },
          })
        );
      });
    }

    this.socket.emit(
      "subscribe",
      {
        handlerType: "users",
      },
      async (error, response) => {
        this.userSubscribed = true;
        // console.log(response);
        let tempObj = {};
        let userEmails = [];
        let userToGroupMap = this.store.getState().userToGroupMap;
        let onlineInGroup = this.store.getState().onlineInGroup;
        let group = this.store.getState().groups;
        let myUid = this.store.getState().user.uid;
        console.log(response);
        response.forEach((element) => {
          tempObj[element.uid] = element;
          userEmails.push({
            uid: element.uid,
            email: element?.profile?.email ?? element?.profile?.displayName,
          });
          if (element.online) {
            Object.keys(userToGroupMap?.[element?.uid] ?? {}).forEach(
              (groupid) => {
                onlineInGroup[groupid] = [
                  ...new Set([...onlineInGroup[groupid], element.uid]),
                ];
              }
            );
          }
        });

        group.forEach((group_obj) => {
          group_obj.members.sort((a, b) => {
            if (a.uid === myUid) return -1;
            if (b.uid === myUid) return 1;
            if (tempObj[a.uid].online) return -1;
            if (tempObj[b.uid].online) return 1;
            return 0;
          });
        });

        // console.log(userEmails);
        // console.log(onlineInGroup, tempObj, this.store, this.actions);
        this.store.dispatch(this.actions.setOnlineInGroup(onlineInGroup));
        this.store.dispatch(this.actions.setUserEamils(userEmails));
        this.store.dispatch(this.actions.setOnlineStatus(tempObj));
        this.store.dispatch(this.actions.addGroupAction(group));
      }
    );
  }
  makeRequest(requestType) {
    return new Promise((res, rej) => {
      this.socket.emit(
        "makeRequest",
        {
          handlerType: "users",
          data: {
            request: requestType,
          },
        },
        (error, response) => {
          if (error) {
            console.log(error);
            rej(error);
          }

          console.log(requestType, response);
          res(response);
        }
      );
    });
  }
}
