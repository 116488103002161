import { hash } from "../../../common/Utils.js";

const DEBUG = true;
const Janus = window.Janus;

export default class JanusGroupHandler {
  constructor(janus, channelName, uid, volume) {
    this.connected = false;
    this.muted = true;
    this.channelName = channelName;
    this.janus = janus;
    this.uid = uid;
    this.pluginHandle = null;
    this.remoteStream = null;
    this.webrtcUp = false;
    this.islineon = false;
    this.autorejoin = false;
    this.roomjoined = false;
    this.roomexists = false;
    this.onJoinedCB = null;
    this.onJsepHandledCB = null;
    this.volume = volume;
    this.localTrack = null;
    this.checkingcount = 0;

    this.init();
  }

  init() {
    this.janus.attach({
      plugin: "janus.plugin.audiobridge",
      opaqueId: "plugapp-" + Janus.randomString(12),
      success: (pluginHandle) => {
        if (DEBUG) console.log("JANUS attach success", this.channelName);
        this.connected = true;
        this.pluginHandle = pluginHandle;

        if (this.autorejoin) {
          this.autorejoin = false;
          this.joinRoom().then(() => {});
        }
      },
      webrtcState: (state) => {
        Janus.log("webrtcState changed to " + state);
      },
      iceState: (state) => {
        Janus.log("ICE state changed to " + state);
        console.log("ICE State changed to " + state);
      },
      mediaState: (state) => {
        Janus.log("mediaState changed to " + state);
      },
      onmessage: (msg, jsep) => {
        if (DEBUG) console.log("JANUS onmessage", msg);
        if (jsep) {
          this.pluginHandle.handleRemoteJsep({ jsep: jsep });
          if (this.onJsepHandledCB) this.onJsepHandledCB();
        }
        if (msg["audiobridge"] === "joined" && msg["id"] && !this.webrtcUp) {
          if (DEBUG) console.log(`----room *${this.channelName}* joined----`);
          this.roomjoined = true;
          if (this.onJoinedCB) this.onJoinedCB();
        }
        if (msg["error_code"] == 487) {
          console.log("shouldn't land here", msg);
        }
      },
      onlocaltrack: (track, on) => {
        Janus.debug("Local track " + (on ? "added" : "removed") + ":", track);

        this.localTrack = track;
      },
      onremotetrack: (track, mid, on) => {
        Janus.debug(
          "Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":",
          track
        );
        if (this.remoteStream || track.kind !== "audio") return;
        if (!on) {
          // Track removed, get rid of the stream and the rendering
          if (this.remoteStream) {
            try {
              var tracks = this.remoteStream.getAudioTracks();
              for (var i in tracks) {
                var mst = tracks[i];
                if (mst) mst.stop();
              }
            } catch (e) {}
          }
          this.remoteStream = null;
        }
        this.remoteStream = new MediaStream();
        this.remoteStream.addTrack(track.clone());

        let audio = document.querySelector(`#${this.channelName}audio`);
        if (!audio) {
          audio = document.createElement("audio");
          audio.id = `#${this.channelName}audio`;
          audio.autoplay = true;
        }
        document.querySelector("body").appendChild(audio);
        Janus.attachMediaStream(audio, this.remoteStream);

        if (this.volume < 50) this.setVolume(0);
      },
      detached: () => {
        this.webrtcUp = false;
        Janus.log(" ::: Got a cleanup notification :::");
        this.remoteStream = null;
        this.autorejoin = true;
        this.init();
      },
      oncleanup: () => {
        this.webrtcUp = false;
        this.roomjoined = false;
        this.remoteStream = null;
        Janus.log(" ::: Got a cleanup notification :::");
      },
    });
  }
  offerConnection(addAudio = false, removeAudio = false) {
    return new Promise((res) => {
      let media = { video: false, audioSend: false, audioRecv: true };
      if (this.webrtcUp && addAudio === true) {
        media = {
          video: false,
          audioRecv: true,
          replaceAudio: true,
        };
      } else if (this.webrtcUp && removeAudio === true) {
        media = {
          video: false,
          audioRecv: true,
          removeAudio: true,
        };
      }
      this.pluginHandle.createOffer({
        media: media,
        customizeSdp: (jsep) => {
          if (jsep.sdp.indexOf("stereo=1") == -1) {
            jsep.sdp = jsep.sdp.replace(
              "useinbandfec=1",
              "useinbandfec=1;stereo=1"
            );
          }
        },
        success: (jsep) => {
          if (DEBUG)
            console.log(
              `----room *${this.channelName}* audio published ${addAudio} ${removeAudio}----`
            );
          Janus.debug("Got SDP!", jsep);
          this.pluginHandle.send({
            message: { request: "configure", muted: !addAudio },
            jsep: jsep,
            success: () => {
              this.webrtcUp = true;
              this.onJsepHandledCB = () => {
                res();
                this.onJsepHandledCB = null;
              };
            },
            error: (error) => {
              Janus.error(
                "offerConnection error:",
                error,
                addAudio,
                removeAudio
              );
              if (DEBUG)
                console.log(
                  "offerConnection error:",
                  error,
                  addAudio,
                  removeAudio
                );
              res();
            },
          });
        },
        error: (error) => {
          Janus.error("WebRTC error:", error, addAudio, removeAudio);
          if (DEBUG)
            console.log("WebRTC error... ", error, addAudio, removeAudio);
          res();
        },
      });
    });
  }

  checkconnected() {
    console.log(
      `----checking connected *${this.channelName}*----`,
      this.pluginHandle
    );
    return new Promise((res) => {
      if (this.connected) {
        this.checkingcount = 0;
        res(true);
      } else {
        if (this.checkingcount > 6) {
          window.onbeforeunload = null;
          window.location.reload(true);
        }
        this.checkingcount++;
        setTimeout(() => res(this.checkconnected()), 2000);
      }
    });
  }

  checkExists() {
    if (DEBUG) console.log("JANUS check exists: ", this.channelName);
    return new Promise((res) => {
      this.pluginHandle.send({
        message: {
          request: "exists",
          room: hash(this.channelName),
        },
        success: (result) => {
          if (DEBUG)
            console.log(
              "JANUS check exists inside existsCb ",
              this.channelName
            );
          res(result.exists);
        },
      });
    });
  }

  create() {
    if (DEBUG) console.log("JANUS create room: ", this.channelName);
    return new Promise((res) => {
      this.pluginHandle.send({
        message: {
          request: "create",
          description: `Channel: ${this.channelName}`,
          permanent: true,
          display: this.uid + "",
          room: hash(this.channelName),
          sampling_rate: 16000,
          default_prebuffering: 4,
          audio_active_packets: 50,
          default_expectedloss: 5,
        },
        success: res,
      });
    });
  }
  mute() {
    console.log("mute called", this.channelName);
    return new Promise((res) => {
      if (this.muted) {
        res();
        return;
      }
      this.muted = true;
      if (this.webrtcUp) {
        this.pluginHandle.muteAudio();
        this.pluginHandle.send({
          message: { request: "configure", muted: true },
          success: res,
          error: res,
        });
      } else {
        res();
      }
    });
  }

  unmute() {
    console.log("unmute called", this.channelName);
    return new Promise((res) => {
      if (!this.muted) {
        res();
        return;
      }
      this.muted = false;
      if (this.webrtcUp) {
        this.pluginHandle.unmuteAudio();
        this.pluginHandle.send({
          message: { request: "configure", muted: false },
          success: res,
          errr: res,
        });
      } else {
        res();
      }
    });
  }

  async lineon() {
    Janus.debug("lineon", this.channelName);
    if (this.islineon == true) return;
    this.islineon = true;

    if (this.webrtcUp) await this.offerConnection(true, false);
    await this.unmute();
  }

  async lineoff() {
    console.log(this.localTrack);
    this.localTrack.stop();
    Janus.debug("lineoff", this.channelName);
    if (this.islineon == false) return;
    this.islineon = false;
    await this.mute();
    if (this.webrtcUp) await this.offerConnection(false, true);
  }

  join() {
    if (DEBUG) console.log("JANUS joining room: ", this.channelName);
    return new Promise((res, rej) => {
      this.onJoinedCB = async () => {
        await this.offerConnection(false, true);
        res();
        this.onJoinedCB = null;
      };
      this.pluginHandle.send({
        message: {
          request: "join",
          display: this.uid + "",
          room: hash(this.channelName),
          group: this.channelName + "",
          quality: 5,
          bitrate: 128000,
          expected_loss: 5,
        },
        success: () => {},
        error: (err) => {
          console.log(err);
          rej();
        },
      });
    });
  }
  async joinRoom() {
    if (this.connected && this.roomjoined && this.webrtcUp) {
      if (this.volume < 50) this.setVolume(100);
      return;
    }
    if (DEBUG) console.log(`----room *${this.channelName}* joining----`);
    const ts = Date.now();
    if (!this.roomexists) {
      const exists = await this.checkExists();
      if (!exists) {
        await this.create();
      }
      this.roomexists = true;
    }
    if (DEBUG)
      console.log(
        `----room *${this.channelName}* exists check timetaken : ${
          Date.now() - ts
        }----`
      );
    if (!this.roomjoined) await this.join();
  }

  async leaveRoom() {
    if (DEBUG)
      console.log(
        `----attempting to leave active room ${this.channelName}----`
      );
    if (this.webrtcUp) {
      this.webrtcUp = false;
      this.roomjoined = false;
      this.islineon = false;
      this.remoteStream = null;
      await this.pluginHandle.hangup();
    }
    if (DEBUG) console.log(`----room left----`);
  }

  setVolume(volume) {
    console.log("setVolume called", volume, this.channelName);
    this.volume = volume;
    const volEnabled = volume > 50;
    this.remoteStream = this.pluginHandle
      ? this.pluginHandle.webrtcStuff
        ? this.pluginHandle.webrtcStuff.remoteStream
        : null
      : null;

    if (this.remoteStream) {
      try {
        let tracks = this.remoteStream.getAudioTracks();
        if (!tracks) tracks = this.remoteStream.getTracks();
        for (let i in tracks) {
          let mst = tracks[i];
          if (mst) mst.enabled = volEnabled;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  disconnect() {
    return new Promise((res) => {
      if (DEBUG) console.log(`----attempting to leave active room----`);
      if (DEBUG) console.log(`----room left----`);
      res(null);
    });
  }
}
