const DEFAULT_CHARS = "abcdefghijklmnopqrstuvwxyz0123456789";

export function delay(ts = 100) {
  return new Promise((res) => {
    setTimeout(res, ts);
  });
}

export function isElectron() {
  // Renderer process
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  )
    return true;
  // Main process
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  )
    return true;
  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  )
    return true;
  return false;
}

export function generateId(length, characters) {
  let result = "";
  if (!characters) characters = DEFAULT_CHARS;
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function padnum(num, reqlen = 3) {
  let ret = num + "";
  while (ret.length < reqlen) ret = "0" + ret;
  return ret;
}

export function hash(obj) {
  if (typeof obj != "string") {
    obj = JSON.stringify(obj) + "";
  }
  var hash = 0;
  for (var i = 0; i < obj.length; i++) {
    var char = obj.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

export function timeDiffSec(date1, date2) {
  return Math.floor(
    Math.abs(new Date(date1).getTime() - new Date(date2).getTime()) / 1000.0
  );
}

export function shuffle(array, seed = Date.now()) {
  const random = (seed) => {
    let x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
  let m = array.length,
    t,
    i;
  while (m) {
    i = Math.floor(random(seed) * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
}

export function extractEmail(text) {
  let emails = text.match(
    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
  );

  if (emails) return emails[0];
  return "";
}

function getOS() {
  var userAgent = (window.navigator.userAgent || "unknown").toLowerCase(),
    platform = (window.navigator.platform || "unknown").toLowerCase(),
    macosPlatforms = ["macintosh", "macintel", "macppc", "mac68k", "macos"],
    windowsPlatforms = ["win32", "win64", "windows", "wince"],
    iosPlatforms = ["iphone", "ipad", "ipod"],
    os = null;
  if (
    macosPlatforms.indexOf(platform) !== -1 ||
    userAgent.indexOf("mac") !== -1
  ) {
    let isIOS =
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
      !window.MSStream;
    os = isIOS ? "iOS" : "Mac OS";
  } else if (
    windowsPlatforms.indexOf(platform) !== -1 ||
    userAgent.indexOf("win") !== -1
  ) {
    os = "Windows";
  } else if (/android/.test(userAgent)) {
    os = "Android";
  } else if (/linux/.test(platform) || userAgent.indexOf("linux") !== -1) {
    os = "Linux";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  }
  return os;
}

export const windowsLink =
  "https://storage.googleapis.com/plug-uploads/releases/win/plugapp-latest.exe";
export const macOsLink =
  "https://storage.googleapis.com/plug-uploads/releases/mac/plugapp-artifact-universal.zip";
export const linuxLink =
  "https://storage.googleapis.com/plug-uploads/releases/linux/plugapp-latest.deb";

export function getOsArray() {
  const os = getOS();
  if (os === "Mac OS") return ["Mac OS", "Windows", "Linux"];
  else if (os === "Windows") return ["Windows", "Linux", "Mac OS"];
  else if (os === "Linux") return ["Linux", "Windows", "Mac OS"];
  else return null;
}

export function getUserAttribute(user, attr) {
  if (user[attr]) return user[attr];
  for (let p in user.providerData)
    if (user.providerData[p][attr]) return user.providerData[p][attr];
  return null;
}

export async function getLoopbackStream(stream) {
  let rtcConnection = null;
  let rtcLoopbackConnection = null;
  let loopbackStream = new MediaStream(); // this is the stream you will read from for actual audio output

  const offerOptions = {
    offerVideo: false,
    offerAudio: true,
    offerToReceiveAudio: false,
    offerToReceiveVideo: false,
  };

  let offer, answer;

  // initialize the RTC connections

  rtcConnection = new RTCPeerConnection();
  rtcLoopbackConnection = new RTCPeerConnection();

  rtcConnection.onicecandidate = (e) =>
    e.candidate &&
    rtcLoopbackConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
  rtcLoopbackConnection.onicecandidate = (e) =>
    e.candidate &&
    rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

  rtcLoopbackConnection.ontrack = (e) =>
    console.log(e) +
    e.streams[0].getTracks().forEach((track) => loopbackStream.addTrack(track));

  // setup the loopback
  rtcConnection.addStream(stream); // this stream would be the processed stream coming out of Web Audio API destination node

  offer = await rtcConnection.createOffer(offerOptions);
  await rtcConnection.setLocalDescription(offer);

  await rtcLoopbackConnection.setRemoteDescription(offer);
  answer = await rtcLoopbackConnection.createAnswer();
  await rtcLoopbackConnection.setLocalDescription(answer);

  await rtcConnection.setRemoteDescription(answer);
  return loopbackStream;
}
