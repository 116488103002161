import { PlugWsClient } from "../../logic/modules/PlugWSClient/index";
import * as Actions from "./Action.js";
import * as JanusActions from "./JanusClientActions.js";
import { getTokenActions, trackEventActions } from "./firebaseActions";
import { delay } from "../../logic/common/Utils";

let plugWsClientInstance = new PlugWsClient();

const socketConnected = (payload) => {
  return {
    type: "SOCKET_CONNECTED",
    payload: payload,
  };
};

const subscribing = (payload) => {
  return {
    type: "SUBSCRIBING",
    payload: payload,
  };
};

export const initializeSocketClient = (store) => {
  return async (dispatch) => {
    await plugWsClientInstance.init(
      store,
      Actions,
      {
        setPlugOff,
        setPlugOn,
        setLineOn,
        setLineOff,
      },
      JanusActions,
      getTokenActions
    );
    dispatch(socketConnected(true));
  };
};

let count = 0;

export const subscribeAction = (subscribeTo) => {
  return async (dispatch) => {
    dispatch(subscribing(true));
    await plugWsClientInstance.subscribe(subscribeTo);
    dispatch(subscribing(false));
  };
};

export const setLineOn = (payload) => {
  return async (dispatch, getState) => {
    await plugWsClientInstance.makeRequest("signals", "setLineOn", payload);
    let { groupid, groupName } = getState().activeGroup;
    console.log(groupid, groupName);
    trackEventActions("lineon", { groupid: groupid, group: groupName });
  };
};
export const setLineOff = (payload) => {
  return async (dispatch, getState) => {
    await plugWsClientInstance.makeRequest("signals", "setLineOff", payload);
    let { groupid, groupName } = getState().activeGroup;
    console.log(groupid, groupName);
    trackEventActions("lineoff", { groupid: groupid, group: groupName });
  };
};

export const socketIsConnected = () => {
  return plugWsClientInstance.isConnected();
};

export const setPlugOn = (auto) => {
  return async (dispatch, getState) => {
    await plugWsClientInstance.subscribe("users");
    await plugWsClientInstance.subscribe("signals");
    dispatch(Actions.userPlugginOn(true));
    await plugWsClientInstance.makeRequest("users", "setUserOnline", null);
    dispatch(Actions.userPlugginOn(false));
    dispatch(Actions.plugON({ plug: true, bgColor: "#00bf1970" }));
    document.documentElement.style.setProperty("--color-plug", "#00bf19");
    trackEventActions("plugon", { auto });
  };
};

export const reAuthenticate = (accessToken, user) => {
  return (dispatch) => {
    // console.log(accessToken);
    plugWsClientInstance.reAuthenticate(accessToken, user);
  };
};

export const setPlugOff = () => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest("users", "setUserOffline", null);
    dispatch(Actions.plugON({ plug: false, bgColor: "rgba(0,0,0,0.3)" }));
    document.documentElement.style.setProperty("--color-plug", "#797979");
    trackEventActions("plugoff");
  };
};

export const createGroup = (payload) => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest("groups", "createGroup", payload);
  };
};

export const updateGroup = (payload) => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest("groups", "updateGroup", payload);
  };
};
export const deleteGroup = (payload) => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest("groups", "deleteGroup", payload);
  };
};

export const disconnectWs = (payload) => {
  return async (dispatch, getState) => {
    try {
      await plugWsClientInstance.disconnect();
    } catch (e) {
      console.log(e);
    }

    console.log("disconnected socket");
  };
};

export const sendMessageAction = (payload) => {
  return async (dispatch) => {
    await plugWsClientInstance.makeRequest("groups", "sendMessage", payload);
    // do something
  };
};
