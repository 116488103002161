import React, { useEffect, useState } from "react";
import { Row, Col, Avatar, Switch, notification, Spin } from "antd";
import notificationBell from "../../../sounds/notification.mp3";
import Loading from "./Loading.jsx";
import LogRocket from "logrocket";
import CreateGroup from "../../Components/CreateGroup/CreateGroup";
import Group from "../../Components/Group/Group";
import GroupSetting from "../../Components/GroupSetting/GroupSetting";
import LineOn from "../../Components/LineOn/LineOn.jsx";
import NewConversation from "../../Components/NewConversation/NewConversation.jsx";
import {
  setPlugOn,
  setPlugOff,
  disconnectWs,
} from "../../../Redux/Actions/WsActions.js";
import {
  plugLogo as plugApp,
  searchLogo,
  cubeLogo,
  userImage,
  copyRight,
  AddLogo,
  LoaderLogo,
} from "../../../assets/index.js";
import "./Home.css";
import {
  showCreateGroup,
  plugON,
  showNewConversation,
} from "../../../Redux/Actions/Action";

import { connect } from "react-redux";
import firebase from "firebase/compat/app";

import {
  initializeSocketClient,
  subscribeAction,
  socketIsConnected,
} from "../../../Redux/Actions/WsActions.js";
import {
  initializeJanusClient,
  disconnectJanus,
} from "../../../Redux/Actions/JanusClientActions.js";
import ChatBox from "../../Components/ChatBox/ChatBox.jsx";
import { delay } from "../../../logic/common/Utils.js";

function Notification(props) {
  useEffect(() => {
    notification.info({
      message: `group ${props.status}`,
      description: `Refresh Page to see Group`,
      placement: "top",
      maxCount: 5,
      onClick: () => {},
    });
  }, []);

  return null;
}

function NewMsgNotification() {
  useEffect(() => {
    let notificationAudio = new Audio(notificationBell);
    notification.info({
      message: `new Message`,
      description: `new Message in group `,
      placement: "top",
      maxCount: 2,
      onClick: () => {},
    });
    try {
      notificationAudio
        .play()
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return null;
}

let _lasthidetime = 0;
function isElectron() {
  // Renderer process
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  )
    return true;
  // Main process
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  )
    return true;
  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  )
    return true;
  return false;
}

function Home(props) {
  const plugOn = props.plugON;
  // console.log(props);
  const [showMsg, setShowMsg] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  // handling switch on and off.
  const handleSwitchChange = async () => {
    if (!plugOn) {
      await props.setPlugOn();
      window.onbeforeunload = () => {
        props.disconnectWs();
        return null;
      };
    } else {
      window.onbeforeunload = null;
      props.disconnectJanus();
      await props.setPlugOff();
      setShowMsg(true);
    }
  };

  useEffect(() => {
    console.log("init");
    LogRocket.init("gnusd1/plugapp");
    LogRocket.identify(props.user.email, {
      name: props.user.displayName,
      email: props.user.email,
    });

    // window.STREAM = null;

    // navigator.mediaDevices
    //   .getUserMedia({ audio: true, video: false })
    //   .then(function (stream) {
    //     console.log("You let me use your mic!");
    //     window.STREAM = stream;

    //     const audioTrack = stream.getAudioTracks();
    //     console.log(audioTrack);
    //     audioTrack[0].enable = false;
    //   })
    //   .catch(function (err) {
    //     console.log("No mic for you!");
    //   });

    window.onbeforeunload = () => {
      props.disconnectWs();
      return null;
    };
    setTimeout(() => {
      window.onbeforeunload = null;
    }, 1000);
    // window.showNotification = (show) => {
    //   if (show) {
    //     console.log("show Notification");
    //     notification.info({
    //       message: `janus Disconected`,
    //       description: `Connecting to janus please wait!!`,
    //       placement: "top",
    //       maxCount: 5,
    //       onClick: () => {},
    //     });
    //     setShowSpinner(true);
    //   } else {
    //     notification.info({
    //       message: `janus Connected`,
    //       description: `Janus connection On`,
    //       placement: "top",
    //       maxCount: 5,
    //       onClick: () => {},
    //     });
    //     setShowSpinner(false);
    //   }
    // };
    async function isScoketConnectedTimer() {
      let connected = socketIsConnected();
      console.log("is Socket Connected " + connected);
      if (!connected) {
        await props.initializeSocketClient(props.store);
        await props.initializeJanusClient();
      }
    }

    setInterval(isScoketConnectedTimer, 30000);

    async function janusAndSocketInit() {
      await props.initializeSocketClient(props.store);

      await props.initializeJanusClient();
    }

    document.addEventListener("visibilitychange", async (ev) => {
      console.log(`Tab state : ${document.visibilityState}`);
      if (document.visibilityState === "visible") {
        //  check if the accessToken has been expired.
        if (_lasthidetime && Date.now() - _lasthidetime > 3600000) {
          await props.initializeSocketClient(props.store);
          await props.initializeJanusClient();
        } else if (
          props.user.stsTokenManager.expirationTime - new Date().getTime() <=
          0
        ) {
          await props.initializeSocketClient(props.store);
          await props.initializeJanusClient();
        }
      } else {
        _lasthidetime = Date.now();
      }
    });

    janusAndSocketInit();

    return () => {
      window.onbeforeunload = null;
      document.documentElement.style.setProperty("--color-plug", "#797979");
      props.disconnectWs();
    };
  }, []);

  return (
    <span
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Row
        style={{
          width: "100%",
          backgroundColor: props.plugON ? "#00bd1921" : "#f7f7f7",
          borderBottom: props.plugON
            ? "solid 2px #00bf1950"
            : "solid 2px #f7f7f7",
        }}
      >
        <Col span={24}>
          <div className="main-header">
            <img
              src={plugApp}
              draggable="false"
              className="plugApp_logo"
              onClick={() => {
                window.location.reload(true);
              }}
            ></img>
            <Switch
              checked={plugOn}
              className="switch"
              size="large"
              style={
                plugOn
                  ? { backgroundColor: "#00bd1921" }
                  : { backgroundColor: "#f7f7f7" }
              }
              checkedChildren={
                <span
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontSize: "15px",
                    opacity: 1,
                    position: "relative",
                  }}
                >
                  online
                </span>
              }
              unCheckedChildren={
                <span
                  style={{
                    color: "black",
                    fontWeight: "bolder",
                    fontSize: "15px",
                  }}
                >
                  offline
                  {props.userPlugginOn ? (
                    <LoaderLogo className="plug_on_loader spinner" />
                  ) : null}
                </span>
              }
              onChange={handleSwitchChange}
            />

            {!plugOn && showMsg ? (
              <div
                onClick={() => {
                  return;
                }}
                className="plugon_msg"
                style={{
                  position: "absolute",
                  bottom: "-60px",
                  left: "350px",
                  backgroundImage:
                    "linear-gradient(to right, #f1a200 0%, #ffc200)",

                  color: "white",
                  padding: "10px 20px",
                  borderRadius: "20px",
                  width: "450px",
                  zIndex: "5",
                }}
              >
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: "15px",
                    marginRight: "5px",
                  }}
                >
                  "Mark yourself online"
                </span>
                during your work hours to have real-time conversations with your
                teammates
              </div>
            ) : null}

            <div style={{ flex: 1 }}></div>
            {/* <div className="header-serach">
              <img src={searchLogo} draggable="false"></img>
            </div> */}
            {/* <div className="add_logo_container">
              <AddLogo className="add_logo" />
              <div className="add_logo_dropdown">
                <p
                  onClick={() => {
                    console.log("new conv. is called");
                    props.showNewConversation(true);
                  }}
                >
                  New Conversation
                </p>
                <p
                  onClick={() => {
                    console.log("new conv. is called");
                    props.showCreateGroupAction(true);
                  }}
                >
                  Create Group
                </p>
              </div>
            </div> */}
            <div
              className="header-create-grp"
              onClick={() => {
                if (!props.plugON) return;
                props.showCreateGroupAction(true);
              }}
              style={{ opacity: props.plugON ? "1" : "0.5" }}
            >
              <img src={cubeLogo} draggable="false"></img>
              <span>create group</span>
            </div>
            <div className="user_img">
              <Avatar
                style={{
                  backgroundColor: "#f1a200",
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  fontSize: "20px",
                  lineHeight: "2.2",
                }}
                size="large"
              >
                {props?.user?.displayName?.[0] || props?.user?.email?.[0]}
              </Avatar>
              <div className="user_drop_menu">
                <span
                  style={{
                    fontWeight: "bolder",
                    fontSize: "13px",
                    padding: "1px 10px",
                  }}
                >
                  {props?.user?.displayName ||
                    props?.user?.email?.split("@")?.[0]}
                </span>
                <span style={{ paddingLeft: "10px" }}>
                  {props?.user?.email}
                </span>
                <span
                  className="logout_"
                  onClick={() => {
                    firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        window.onbeforeunload = null;
                        props.disconnectJanus();
                        props.disconnectWs();
                        window.location.reload();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                >
                  LogOut
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {props.showCreateGroup ? <CreateGroup /> : null}
      {props.showLineOnPopUp ? <LineOn /> : null}
      {props.showGroupSettings ? <GroupSetting /> : null}
      {props.showChatBox ? <ChatBox /> : null}
      {props.showNewConversationPopUp ? <NewConversation /> : null}
      {props.newGroup ? <Notification status={props.newGroup} /> : null}
      {props.newMessage && props.showChatBox !== props.newMessage ? (
        <NewMsgNotification />
      ) : null}
      {showSpinner ? (
        <Spin style={{ position: "absolute", fontSize: "50px" }} />
      ) : null}
      <div
        className="groups_container"
        style={{ opacity: props.plugON ? 1 : 0.2 }}
      >
        {props.groups.length === 0 ? (
          <>
            {props.groupExist ? (
              <React.Fragment>
                <Loading />
                <Loading />
              </React.Fragment>
            ) : (
              <div
                style={{
                  height: "60vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "40px",
                }}
              >
                <p className="no_group_msg">
                  You are the only one from your organization. Invite more
                  people to Plug and start having real time conversations
                  easily.
                </p>

                <button
                  className="no_group_invite_btn"
                  onClick={() => {
                    window.onbeforeunload = null;

                    window.location.href =
                      "mailto:?subject=Invitation to join Plug - your online walkie talkie.&body=Please join at plugapp.net. plug is an online walkie talkie and will help us communicate easily during our working hours.%0D%0A %0D%0AJoin now https://plugapp.net";

                    window.onbeforeunload = () => {
                      props.disconnectWs();
                      return null;
                    };
                  }}
                >
                  Invite More Members
                </button>
              </div>
            )}
          </>
        ) : (
          props.groups.map((obj) => {
            return (
              <Group
                groupName={obj.name}
                members={obj.members}
                groupid={obj.groupid}
                plug={plugOn}
                conversation={obj.conversation}
                key={`grp-li-${obj.groupid}`}
              />
            );
          })
        )}

        <div style={{ textAlign: "center" }}>
          <img
            src={copyRight}
            style={{ height: "30px", textAlign: "center" }}
            draggable="false"
          />
        </div>
      </div>
    </span>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  plugOnAction: (payload) => dispatch(plugON(payload)),
  showCreateGroupAction: (payload) => dispatch(showCreateGroup(payload)),
  showNewConversation: (payload) => {
    dispatch(showNewConversation(payload));
  },

  initializeSocketClient: (store) => {
    return dispatch(initializeSocketClient(store));
  },
  initializeJanusClient: () => {
    return dispatch(initializeJanusClient());
  },
  subscribeAction: (subscribeTo) => {
    dispatch(subscribeAction(subscribeTo));
  },
  setPlugOn: () => {
    return dispatch(setPlugOn());
  },
  setPlugOff: () => {
    return dispatch(setPlugOff());
  },
  disconnectWs: () => {
    dispatch(disconnectWs());
  },
  disconnectJanus: () => {
    dispatch(disconnectJanus());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
