import React, { useEffect, useState, useRef } from "react";
import "../CreateGroup/CreateGroup.css";
import "./LineOn.css";
import { Avatar, Divider } from "antd";
import { connect } from "react-redux";
import { showLineOnPopUp, activateGroup } from "../../../Redux/Actions/Action";
import {
  trackEventActions,
  uploadFileToCloud,
  getDownloadableUrl,
} from "../../../Redux/Actions/firebaseActions";
import { UploadOutlined, AudioOutlined } from "@ant-design/icons";
import { FileIcon, defaultStyles } from "react-file-icon";
import { message, Spin } from "antd";
import { setLineOff } from "../../../Redux/Actions/WsActions";
import {
  GroupLogo,
  OnlineLogo,
  TalkLogo,
  ChatSend,
  chatLogo,
  CrossLogo,
} from "../../../assets";
import { hash } from "../../../logic/common/Utils.js";
import { lineOffAction } from "../../../Redux/Actions/JanusClientActions";
import { sendMessageAction } from "../../../Redux/Actions/WsActions";
import ParsedMsg from "../ParsedMsg/ParsedMsg";
import { getGroupMessageHistory } from "../../../Redux/Actions/firebaseActions";
import ChatAudio from "../ChatAudio/ChatAudio";
import RecordView from "../RecordView/RecordView";
// random dummy list of user who use this image;

const colorList = [
  "#f56a90",
  "#7265e6",
  "#ffbf9f",
  "#00a2ae",
  "#f1a200",
  "#00a2ae",
  "#7265e6",
  "#f56a90",
  "#ffbf9f",
  "#ffbf9f",
  "#f1a200",
];

function ScrollBottom(props) {
  useEffect(() => {
    let ref = props.refrence;
    if (ref.current.scrollHeight > ref.current.clientHeight) {
      setTimeout(() => {
        ref.current.scrollTop =
          ref.current.scrollHeight + ref.current.clientHeight;
      }, 250);
    }
  }, []);
}

function LineOn(props) {
  const [textMessage, setMessage] = useState("");

  const [fileType, setFileTye] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showRecordPopUp, setViewRecordPopUp] = useState(false);
  // console.log(props);
  const btnRef = useRef();
  const ref = useRef();
  useEffect(() => {
    let modal = document.getElementById("modal");
    window.onclick = (event) => {
      if (event.target === modal) {
        btnRef.current.click();
      }
    };

    return () => {
      window.onclick = null;
    };
  }, []);

  const handleSendMessage = async () => {
    if (textMessage === "" && !fileName && !fileType) return;

    let payload = {};
    payload.groupid = props.activeGroup.groupid;
    payload.displayName = props?.user?.displayName;
    try {
      if (file && fileName && fileType) {
        if (file.size > 52428800) {
          message.info("file size more than 50mb");
          setFile(null);
          setFileName(null);
          setFileTye(null);
          setMessage("");
          return;
        }
        payload.type = "ATTACHMENT";
        // save the file in cloude storage and get the url.
        setLoading(true);

        const Url = await uploadFileToCloud(file, fileType);
        setLoading(false);
        payload.filename = fileName;
        payload.url = Url;
        payload.fileext = fileType;
        payload.size = file.size;
        if (textMessage) payload.message = textMessage;
        console.log(payload);
      } else {
        payload.type = "TEXT";

        payload.message = textMessage;
      }

      await props.sendMessageAction(payload);
    } catch (e) {
      console.log(e);
    }
    setFile(null);
    setFileName(null);
    setFileTye(null);
    setMessage("");
  };

  const handleFileChange = (event) => {
    if (!event.target.files[0]) return;
    const fileName = event.target.files[0].name;

    const iconType = fileName.substr(
      fileName.lastIndexOf(".") + 1,
      fileName.length
    );
    setFile(event.target.files[0]);
    setFileName(fileName.substr(0, fileName.lastIndexOf(".")));
    setFileTye(iconType);
  };
  const handleDownloadFile = async (path, filename, fileext) => {
    const url = await getDownloadableUrl(path);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename + "." + fileext);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSendMessage();
    }
  };

  const onlineStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#00bf19",
    border: "4px solid #f2fcf3",
    position: "absolute",
    right: "0px",
  };
  const busyStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: "#d63131",
    border: "4px solid #fff0f0",
    position: "absolute",
    right: "10px",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bolder",
    lineHeight: "3",
  };

  function getTime(timestamp) {
    let time = new Date(timestamp);

    let dateParts = time.toDateString().split(" ");
    return (
      dateParts[2] + " " + dateParts[1] + " , " + time.toLocaleTimeString()
    );
  }

  return (
    <div className="modal_box" id="modal" style={{ display: "flex" }}>
      <div
        className="modal_container"
        style={{
          boxShadow: " 0 2px 8px 4px rgba(0, 191, 25, 0.6)",
          height: "550px",
        }}
      >
        <button
          className="modal_lineOff_logo"
          onClick={() => {
            props.closeLineOnAction(props.activeGroup.groupid);
          }}
          ref={btnRef}
        >
          Close
        </button>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            textOverflow: "ellipsis",
            whiteSpace: "wrap",
            overflow: "hidden",
          }}
        >
          <h2
            style={{
              fontFamily: "Inter",
              fontSize: "30px",
              fontWeight: "bolder",
              marginLeft: "40px",
              marginTop: "60px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            className="lineOn_heading"
          >
            <span
              style={{
                color: "#f1a200",
                fontWeight: "bolder",
                fontFamily: "Inter",
                marginLeft: "5px",
              }}
            >
              {props.activeGroup.groupName}
            </span>
          </h2>
          <div
            className="modal_crg_add_box modal_line_on_box"
            style={{ marginTop: "0px" }}
          >
            <div className="modal_crg_add">
              <span className="lineOn_status_group">
                <span className="lineOn_box_status">
                  <GroupLogo style={{ height: "30px" }} />
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    {props.activeGroup.members.length}
                  </span>
                </span>
                <span className="lineOn_box_status">
                  <OnlineLogo style={{ height: "30px" }} />
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    {props.onlineInGroup[props.activeGroup?.groupid]?.length}
                  </span>
                </span>
                <span
                  className="lineOn_box_status"
                  style={{ marginRight: "0px" }}
                >
                  <TalkLogo style={{ height: "30px" }} />
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    {
                      Object.keys(
                        props.lineOnStatus?.[props.activeGroup?.groupid] ?? {}
                      ).length
                    }
                  </span>
                </span>
              </span>
              <div
                style={{
                  overflowY: "auto",
                  width: "90%",
                  maxHeight: "400px",
                  paddingRight: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    padding: "20px",
                    flexWrap: "wrap",
                  }}
                  className="user_group_box"
                >
                  {props.activeGroup.members.filter((obj) => {
                    return props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                      obj?.uid
                    ];
                  }).length ? (
                    <Divider>Talking Members</Divider>
                  ) : null}
                  {props.activeGroup.members.map((obj, index) => {
                    return (
                      <React.Fragment key={`user-li-${index}`}>
                        {props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                          obj?.uid
                        ] ? (
                          <span className="lineOn_user_img_box">
                            {obj.photoURL ? (
                              <img
                                style={{
                                  borderRadius: "50%",
                                  padding: "2px",
                                  border:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activeGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "solid 3px #00bf19"
                                      : "solid 3px  rgba(255,255,255,0)",
                                  outline:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activateGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "2px solid  #00bf1921"
                                      : "2px solid  rgba(255,255,255,0)",
                                  outlineOffset: "3px",

                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                                className="group_user_img"
                                src={obj.photoURL}
                              />
                            ) : (
                              <Avatar
                                style={{
                                  borderRadius: "50%",
                                  padding: "2px",
                                  backgroundClip: "content-box",

                                  border:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activeGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "solid 3px #00bf19"
                                      : "solid 3px  rgba(255,255,255,0)",
                                  outline:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activateGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "solid 2px #00bf1921"
                                      : "solid 2px rgba(255,255,255,0)",
                                  outlineOffset: "3px",
                                  backgroundColor:
                                    colorList[hash(obj.displayName) % 11],
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  width: "50px",
                                  height: "50px",
                                  lineHeight: "2",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {obj?.displayName?.[0]?.toUpperCase() ?? "@"}
                              </Avatar>
                            )}

                            <span
                              style={{
                                width: "65px",
                                textAlign: "center",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {props.user.uid === obj.uid
                                ? "you"
                                : obj.displayName
                                ? obj.displayName
                                : "@someone"}
                            </span>
                          </span>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                  {props.activeGroup.members.filter((obj) => {
                    return (
                      props.onlineStatus?.[obj?.uid]?.online &&
                      !props.busyStatus?.[obj?.uid] &&
                      !props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                        obj?.uid
                      ]
                    );
                  }).length ? (
                    <Divider>Online Members</Divider>
                  ) : null}
                  {props.activeGroup.members.map((obj, index) => {
                    return (
                      <React.Fragment key={`user-li-${index}`}>
                        {props.onlineStatus?.[obj?.uid]?.online &&
                        !props.busyStatus?.[obj?.uid] &&
                        !props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                          obj?.uid
                        ] ? (
                          <span className="lineOn_user_img_box">
                            {obj.photoURL ? (
                              <img
                                style={{
                                  borderRadius: "50%",
                                  padding: "2px",
                                  border:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activeGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "solid 3px #00bf19"
                                      : "solid 3px  rgba(255,255,255,0)",
                                  outline:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activateGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "2px solid  #00bf1921"
                                      : "2px solid  rgba(255,255,255,0)",
                                  outlineOffset: "3px",

                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                                className="group_user_img"
                                src={obj.photoURL}
                              />
                            ) : (
                              <Avatar
                                style={{
                                  borderRadius: "50%",
                                  padding: "2px",
                                  border: "solid 3px  rgba(255,255,255,0)",
                                  outline: "2px solid  rgba(255,255,255,0)",
                                  outlineOffset: "3px",
                                  backgroundColor:
                                    colorList[hash(obj.displayName) % 11],
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  width: "50px",
                                  height: "50px",
                                  lineHeight: "2",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {obj?.displayName?.[0]?.toUpperCase() ?? "@"}
                              </Avatar>
                            )}

                            <span style={onlineStyle} key={index}></span>

                            <span
                              style={{
                                width: "65px",
                                textAlign: "center",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {props.user.uid === obj.uid
                                ? "you"
                                : obj.displayName
                                ? obj.displayName
                                : "@someone"}
                            </span>
                          </span>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                  {props.activeGroup.members.filter((obj) => {
                    return (
                      props.busyStatus?.[obj?.uid] &&
                      !props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                        obj?.uid
                      ]
                    );
                  }).length ? (
                    <Divider>Busy Members</Divider>
                  ) : null}
                  {props.activeGroup.members.map((obj, index) => {
                    return (
                      <React.Fragment key={`user-li-${index}`}>
                        {props.busyStatus?.[obj?.uid] &&
                        !props.lineOnStatus?.[props?.activeGroup?.groupid]?.[
                          obj?.uid
                        ] ? (
                          <span className="lineOn_user_img_box">
                            {obj.photoURL ? (
                              <img
                                style={{
                                  borderRadius: "50%",
                                  padding: "2px",
                                  border:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activeGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "solid 3px #00bf19"
                                      : "solid 3px  rgba(255,255,255,0)",
                                  outline:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activateGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "2px solid  #00bf1921"
                                      : "2px solid  rgba(255,255,255,0)",
                                  outlineOffset: "3px",

                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                                className="group_user_img"
                                src={obj.photoURL}
                              />
                            ) : (
                              <Avatar
                                style={{
                                  borderRadius: "50%",
                                  padding: "2px",
                                  border: "solid 3px  rgba(255,255,255,0)",
                                  outline: "2px solid  rgba(255,255,255,0)",
                                  outlineOffset: "3px",
                                  backgroundColor:
                                    colorList[hash(obj.displayName) % 11],
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  width: "50px",
                                  height: "50px",
                                  lineHeight: "2",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {obj?.displayName?.[0]?.toUpperCase() ?? "@"}
                              </Avatar>
                            )}

                            <span style={busyStyle} key={index}>
                              -
                            </span>

                            <span
                              style={{
                                width: "65px",
                                textAlign: "center",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {props.user.uid === obj.uid
                                ? "you"
                                : obj.displayName
                                ? obj.displayName
                                : "@someone"}
                            </span>
                          </span>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                  {props.activeGroup.members.filter((obj) => {
                    return !props.onlineStatus?.[obj?.uid]?.online;
                  }).length ? (
                    <Divider>Offline Members</Divider>
                  ) : null}
                  {props.activeGroup.members.map((obj, index) => {
                    return (
                      <React.Fragment key={`user-li-${index}`}>
                        {!props.onlineStatus?.[obj?.uid]?.online ? (
                          <span className="lineOn_user_img_box">
                            {obj.photoURL ? (
                              <img
                                style={{
                                  borderRadius: "50%",
                                  padding: "2px",
                                  border:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activeGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "solid 3px #00bf19"
                                      : "solid 3px  rgba(255,255,255,0)",
                                  outline:
                                    props.plugON &&
                                    props.lineOnStatus?.[
                                      props?.activateGroup?.groupid
                                    ]?.[obj?.uid]
                                      ? "2px solid  #00bf1921"
                                      : "2px solid  rgba(255,255,255,0)",
                                  outlineOffset: "3px",

                                  cursor: "pointer",
                                  fontSize: "25px",
                                }}
                                className="group_user_img"
                                src={obj.photoURL}
                              />
                            ) : (
                              <Avatar
                                style={{
                                  borderRadius: "50%",
                                  padding: "2px",
                                  border: "solid 3px  rgba(255,255,255,0)",
                                  outline: "2px solid  rgba(255,255,255,0)",
                                  outlineOffset: "3px",
                                  backgroundColor:
                                    colorList[hash(obj.displayName) % 11],
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  width: "50px",
                                  height: "50px",
                                  lineHeight: "2",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {obj?.displayName?.[0]?.toUpperCase() ?? "@"}
                              </Avatar>
                            )}

                            <span
                              style={{
                                width: "65px",
                                textAlign: "center",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {props.user.uid === obj.uid
                                ? "you"
                                : obj.displayName
                                ? obj.displayName
                                : "@someone"}
                            </span>
                          </span>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="modal_crg_list modal_lineOn_chat"
              style={{ alignSelf: "stretch", height: "100%" }}
            >
              {/* <h3>Group Members: {selectedList.length}</h3> */}
              <div
                style={{
                  overflowY: "auto",
                  width: "90%",
                  maxHeight: "400px",
                  height: "85%",
                  paddingRight: "10px",
                  display: "flex",
                  flexDirection: props.groupsChats?.[
                    props?.activeGroup?.groupid
                  ]?.length
                    ? "column-reverse"
                    : "column",
                }}
                ref={ref}
              >
                {props.groupsChats?.[props?.activeGroup?.groupid]?.length ? (
                  <React.Fragment>
                    <div style={{ flex: "1", flexShrink: "1" }}></div>
                    {props.groupsChats[props.activeGroup.groupid].map(
                      (msgObj, idx) => {
                        return (
                          <div
                            key={`${msgObj.groupid}-${msgObj.createdat}`}
                            style={{
                              fontFamily: "Inter",
                              marginBottom: "20px",
                              alignSelf:
                                msgObj.senderuid === props.user.uid
                                  ? "flex-end"
                                  : "flex-start",
                              width: "50%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems:
                                msgObj.senderuid === props.user.uid
                                  ? "flex-end"
                                  : "flex-start",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                color:
                                  colorList[
                                    hash(
                                      msgObj?.senderuid?.split("@")?.[0] ??
                                        msgObj?.senderemail?.split("@")?.[0]
                                    ) % 11
                                  ],
                                marginBottom: "0px",
                                fontWeight: "bolder",
                                fontFamily: "Inter",
                              }}
                            >
                              {msgObj.senderuid === props.user.uid
                                ? "you"
                                : msgObj.sendername
                                ? msgObj.sendername
                                : msgObj.senderemail?.split("@")?.[0]}
                            </p>
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#121212",
                                marginBottom: "0px",
                                fontFamily: "Inter",
                                fontWeight: 500,
                                display: "flex",
                                flexDirection: "column",
                                alignItems:
                                  msgObj.senderuid === props.user.uid
                                    ? "flex-end"
                                    : "flex-start",
                              }}
                              className="fileIconMsg"
                            >
                              {msgObj.url ? (
                                <div
                                  className="uploadedFile"
                                  onClick={() => {
                                    handleDownloadFile(
                                      msgObj.url,
                                      msgObj.filename,
                                      msgObj.fileext
                                    );
                                  }}
                                >
                                  <FileIcon
                                    extension={msgObj.fileext}
                                    {...defaultStyles[msgObj.fileext]}
                                    color="#f1a2008f"
                                  />
                                </div>
                              ) : null}
                              {msgObj.url ? (
                                <React.Fragment>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      color: "#444",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    {msgObj.filename + "." + msgObj.fileext}
                                  </p>
                                  <div
                                    style={{ fontSize: "10px", color: "#aaa" }}
                                  >
                                    Click to Download !
                                  </div>
                                </React.Fragment>
                              ) : null}
                              {msgObj.mediaurl ? (
                                <ChatAudio url={msgObj.mediaurl} />
                              ) : null}
                              {/* {msgObj.filename ? (
                                <p>{msgObj.filename}</p>
                              ) : null} */}
                              {msgObj.text ? (
                                <ParsedMsg text={msgObj.text} />
                              ) : null}
                            </div>
                            <p
                              style={{
                                fontSize: "11px",
                                color: "#121212",
                                fontFamily: "Inter",
                                marginBottom: "0px",
                                opacity: "0.5",
                              }}
                            >
                              {getTime(msgObj.createdat)}
                            </p>
                          </div>
                        );
                      }
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {props.hideLoadingChat?.[
                        props?.activeGroup?.groupid
                      ] ? null : (
                        <button
                          style={{}}
                          className="load_more_btn"
                          onClick={() => {
                            props
                              .findMoreMessage(props?.activeGroup?.groupid)
                              .then(() => {})
                              .catch((e) => {
                                console.log(e);
                              });
                          }}
                        >
                          load more
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "30px",
                    }}
                  >
                    <img src={chatLogo} alt="" className="chat_logo" />
                    <span style={{ opacity: 0.6 }}>Say Hello!!</span>
                  </div>
                )}
                {props.newMessage ? <ScrollBottom refrence={ref} /> : null}
              </div>
              <input
                className="chat_input lineOn_chat"
                placeholder="Write something in Group"
                value={textMessage}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                onKeyDown={handleKeyPress}
                disabled={showRecordPopUp ? true : false}
              />
              <ChatSend
                className="chat_send_logo lineOn_chat_logo"
                onClick={() => {
                  handleSendMessage();
                }}
                style={{
                  color: textMessage || fileName ? "#f1a200" : "#121212",
                  opacity: textMessage || fileName ? 1 : 0.3,
                }}
              />
              {fileType ? (
                <div className="fileIcon lineOn_fileIcon">
                  {loading ? <Spin /> : null}
                  <CrossLogo
                    style={{
                      alignSelf: "flex-end",
                      display: loading ? "none" : "block",
                    }}
                    onClick={() => {
                      setFile(null);
                      setFileName(null);
                      setFileTye(null);
                      setMessage("");
                    }}
                  />
                  <FileIcon
                    extension={fileType}
                    {...defaultStyles[fileType]}
                    style={{ height: "50px", width: "30px" }}
                    color="#f1a2008f"
                  />
                  <p
                    style={{
                      color: "#f1a200",
                      width: "70%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textAlign: "center",
                    }}
                  >
                    {fileName}
                  </p>
                </div>
              ) : null}
              <label
                style={{
                  position: "absolute",
                  right: "26%",
                  bottom: "18px",
                  cursor: "pointer",
                  // fontWeight: "bolder",
                  // color: "white",
                  // border: "1px solid #f1a200",
                  // padding: "2px 5px",
                  // borderRadius: "5px",
                  // backgroundColor: "#f1a200",
                }}
              >
                <UploadOutlined className="upload_lineon_btn" />
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />
              </label>
              <label
                className="audio_label"
                onClick={() => {
                  setViewRecordPopUp(true);
                }}
              >
                <AudioOutlined className="voice_btn" />
              </label>
              {showRecordPopUp ? (
                <RecordView
                  closeRecordPopUp={() => {
                    setViewRecordPopUp(false);
                  }}
                  lineOnChat={true}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  closeLineOnAction: (groupid) => {
    dispatch(setLineOff({ groupid: groupid }));
    dispatch(lineOffAction(groupid));
  },
  sendMessageAction: (payload) => {
    return dispatch(sendMessageAction(payload));
  },
  findMoreMessage: (groupid) => {
    return dispatch(getGroupMessageHistory(groupid, true));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(LineOn);
